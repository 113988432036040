/* - Existing upload file utils (inc. these) will be moved up a level to use in both ModalJourneyController and UploadFileToCaseModalWizard */

import dayjs from "dayjs"
import {
  formatDate,
  isDateLessThanSetNumberOfMinutesFromNow,
} from "../../../../utils/datetimes/parseAndCompare"
import { DateTimeFormat } from "../../../../utils/datetimes/constants"
import { isNullEmptyOrUndefined } from "../../../../utils/assertions/typeAssertions"
import { deleteFile } from "../../../../api/lib/node/node"
import { alertMessageErrorsText } from "../../AlertMessage/alertMessageText"

export const errorToMessageObject = {
  noFiles: alertMessageErrorsText.noFiles,
  hasErrorAndShowFailed: alertMessageErrorsText.genericPartialUpload,
  fileName: alertMessageErrorsText.fileName,
  fileNameLength: alertMessageErrorsText.fileNameLength,
  fileType: alertMessageErrorsText.mimeType,
  genericUploadError: alertMessageErrorsText.genericUpload,
  genericApiError: alertMessageErrorsText.generic,
  fileExtensionsOnError: (fileExt: string) =>
    `${alertMessageErrorsText.fileType} Please make sure all files are either ${fileExt}.`,
}

const singleFileMessage = {
  title: "Supported File Formats",
  message: [
    "PDF: All formats",
    "Docs: .doc, .docx, .docm, .xml, .rtf",
    "Image: .jpg, .jpeg, .png, .heic, .heif",
    "Sheets: .xls, .xlsx",
    "Powerpoint: .pptx",
  ],
}

const multiFileMessage = {
  title: "Supported File Formats",
  message: ["PDF: All formats", "Image: .jpg, .jpeg, .png"],
}

export const tooltipMessages = {
  singleFileMessage: singleFileMessage,
  multiFileMessage: multiFileMessage,
}

export const isAcceptedFileExtension = (
  file: File,
  acceptedFileExtensions: string[]
) => {
  const fileExtension = file.name.split(".").pop()
  if (!fileExtension) return false

  if (acceptedFileExtensions.includes(`.${fileExtension.toLowerCase()}`)) {
    return true
  }

  return false
}

const fileTypeMapping: { [key: string]: string[] } = {
  ".pdf": ["application/pdf"],
  ".doc": ["application/msword"],
  ".docx": [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ],
  ".docm": ["application/vnd.ms-word.document.macroenabled.12"],
  ".xml": ["application/xml"],
  ".rtf": ["application/rtf"],
  ".jpg": ["image/jpeg"],
  ".jpeg": ["image/jpeg"],
  ".png": ["image/png"],
  ".heic": ["image/heic"],
  ".heif": ["image/heif"],
  ".xls": ["application/vnd.ms-excel"],
  ".xlsx": [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ],
  ".pptx": [
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ],
}

/**
 * @param acceptedFileExtensions - List of accepted file extensions, ex - [".jpeg", ".jpg", ".png"]
 * @returns Comma-separated list of accepted file types, ex - ".jpeg,image/jpeg,.jpg,.png,image/png"
 * Throws an error if the file extension does not start with a period (.)
 * Throws an error if the file extension is not supported
 */
export const getAllAcceptedFileTypesFromExtensions = (
  acceptedFileExtensions?: string[]
) => {
  if (!acceptedFileExtensions) return ""

  const accept: Set<string> = new Set()

  acceptedFileExtensions.forEach((extension) => {
    if (extension.startsWith(".")) {
      const mimeTypes = fileTypeMapping[extension]
      if (mimeTypes) {
        mimeTypes.forEach((mimeType) => {
          accept.add(extension)
          accept.add(mimeType)
        })
      } else {
        throw Error("File extension is not supported")
      }
    } else {
      throw Error("File extension must start with a period (.)")
    }
  })

  return Array.from(accept).join(",")
}

/**
 * Some devices, when capturing using the camera, generate file names with illegal characters on our platform.  This function
 * detects if the image is less than 1 minute old and renames the file to HH-mm-ss.<extension>
 */
export const updateDefaultNameForRecentlyCreatedImages = (file: File): File => {
  const hasAnImageExtension = isAcceptedFileExtension(file, [
    ".jpg",
    ".jpeg",
    ".png",
    ".heic",
    ".heif",
  ])

  const fileExtension = file.name.split(".").pop()
  // adding one minute to the last modified date to check if it has been created more than a minute ago
  // this would indicate that a photo was taken on a mobile/ tablet device
  const shouldUpdateName =
    hasAnImageExtension &&
    !isDateLessThanSetNumberOfMinutesFromNow({
      disDate: dayjs(file.lastModified).add(1, "minute").toDate(),
      minutes: 0,
    })
  const currentTime = formatDate({
    date: new Date().toISOString(),
    format: DateTimeFormat.timeWithDashes,
  })

  // changing the time format to HHhMMmSSs (11h10m46s)
  const updatedName = shouldUpdateName
    ? currentTime
        .replace(/-/g, "")
        .replace(/(\d{2})(\d{2})(\d{2})/, "$1h$2m$3s")
        .concat("." + (fileExtension ?? ""))
    : file.name

  return new File([file], updatedName, {
    type: file.type,
    lastModified: file.lastModified,
  })
}

/**
 *
 * @param file
 * @returns
 * Returns true if the file type is in the accepted file types list
 */
export const isAcceptedFileType = (file: File) => {
  const acceptedFileTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-word.document.macroenabled.12",
    "application/xml",
    "application/rtf",
    "image/jpeg",
    "image/png",
    "image/heic",
    "image/heif",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ]
  if (acceptedFileTypes.includes(file.type)) {
    return true
  }

  return false
}

export const removeFile = async (id?: string) => {
  if (isNullEmptyOrUndefined(id)) return
  try {
    await deleteFile({ fileId: id! })
  } catch (err) {
    // we shouldn't need to do anything here
    // BE will handle removing unattached files that werent deleted after some time
  }
}
