// TODO: remove this once we have the correct types
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from "react"

//shouldScrollOn can be anything where a change in value should trigger the scroll to top
export const useScrollToTopOnChange = (shouldScrollOn: any) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [shouldScrollOn])
}
