// TODO: remove this once we have the correct types
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeEvent,
  Dispatch,
  FC,
  KeyboardEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react"

import { IFile } from "../../../../../api/api-client/api-types"
import { postUploadFileToFolder } from "../../../../../api/lib/node/node"
import { CaseDocument } from "../../../../../api/lib/workflow/models/GetCaseDocumentResponse"
import { postUploadCaseDocument } from "../../../../../api/lib/workflow/workflow"
import QuestionMarkCircle from "../../../../../design-tokens/icons/theme/coop/QuestionMarkCircle"
import { MaxFileNameLength } from "../../../../../utils/consts/consts"
import {
  InputFieldType,
  validateInput,
} from "../../../../../utils/forms/validateInputs"
import { BUTTON_VARIANT, Button } from "../../../../atoms/Button"
import { LoadingSpinner } from "../../../../atoms/LoadingSpinner/LoadingSpinner"
import AlertMessage, { MessageType } from "../../../AlertMessage/AlertMessage"
import { alertMessageErrorsText } from "../../../AlertMessage/alertMessageText"
import { ModalContent, ModalHeader } from "../../../Modal"
import { Tooltip, TooltipMessageType } from "../../../Tooltip/Tooltip"
import { UploadWizardSteps } from "../../UploadFileToCaseModalWizard"
import {
  getAllAcceptedFileTypesFromExtensions,
  isAcceptedFileExtension,
  isAcceptedFileType,
  tooltipMessages,
  updateDefaultNameForRecentlyCreatedImages,
} from "../../../ModalJourneyController/utils/utils"

import "./UploadFileToCaseModal.css"

interface UploadFileToCaseModalProps {
  uploadedViaCategory: boolean
  workflowId?: string
  workflowVersion?: string
  caseId?: string
  folderId?: string | null
  requestNameFromCategory?: string
  setCurrentStep: (step: UploadWizardSteps) => void
  setDocumentsToUpload: (documents: CaseDocument[]) => void
  setMultiPageFiles: Dispatch<SetStateAction<IFile[]>>
  setFailedMultiPageFileNames?: Dispatch<SetStateAction<string[]>>
  setIsUploading: Dispatch<SetStateAction<boolean>>
  setUnsavedChanges?: (unsavedChanges: boolean) => void
  removeFile?: (fileId: string) => void
}

export const UploadFileToCaseModal: FC<UploadFileToCaseModalProps> = ({
  uploadedViaCategory,
  workflowId,
  workflowVersion,
  caseId,
  folderId,
  requestNameFromCategory,
  setCurrentStep,
  setDocumentsToUpload,
  setMultiPageFiles,
  setFailedMultiPageFileNames,
  setUnsavedChanges,
  setIsUploading,
  removeFile,
}) => {
  const [isUploadingSingle, setIsUploadingSingle] = useState(false)
  const [isUploadingMulti, setIsUploadingMulti] = useState(false)
  const [multiUploadProgressCounter, setMultiUploadProgressCounter] =
    useState(1)
  const [multiUploadFileCounter, setMultiUploadFileCounter] = useState(0)
  const [genericError, setGenericError] = useState(false)
  const [hasTooLongFileName, setHasTooLongFileName] = useState(false)
  const [hasAcceptedFileTypeError, setHasAcceptedFileTypeError] =
    useState(false)
  const [fileExtensionsOnError, setFileExtensionsOnError] = useState("")
  const [hasValidFileName, setHasValidFileName] = useState(true)
  const [isUsingAppleDevice, setIsUsingAppleDevice] = useState(false)

  const fileInputRef = useRef<HTMLInputElement>(null)
  const multiFileInputRef = useRef<HTMLInputElement>(null)

  const isUploadingSingleOrMulti = isUploadingSingle || isUploadingMulti

  useEffect(() => {
    const isMSStreamSupported = (window: Window): boolean => {
      return "MSStream" in window
    }
    // keeping the vendor and opera for outdated browsers
    const userAgent =
      window.navigator.userAgent ||
      window.navigator.vendor ||
      (window as any).opera
    const isIOS =
      /iPad|iPhone|iPod/.test(userAgent) && !isMSStreamSupported(window)
    if (isIOS) {
      setIsUsingAppleDevice(true)
    }
  }, [])

  const validateFilesForUpload = (
    files: FileList,
    isUsingAppleDevice: boolean
  ): { shouldProceedWithUpload: boolean; updatedFiles: File[] } => {
    let updatedFiles: File[] = []

    for (let i = 0; i < files.length; i++) {
      const fileWithUpdatedName = updateDefaultNameForRecentlyCreatedImages(
        files[i]
      )

      if (
        !isAcceptedFileExtension(fileWithUpdatedName, [
          ".pdf",
          ".doc",
          ".docx",
          ".docm",
          ".xml",
          ".rtf",
          ".jpg",
          ".jpeg",
          ".png",
          ".heic",
          ".heif",
          ".xls",
          ".xlsx",
          ".pptx",
        ])
      ) {
        setFileExtensionsOnError(
          "PDF, DOC, DOCX, DOCM, XML, RTF, JPG, JPEG, PNG, HEIC, HEIF, XLS, XLSX or PPTX"
        )
        return { shouldProceedWithUpload: false, updatedFiles: [] }
      }

      const isHeicFileExtension =
        fileWithUpdatedName?.name?.split(".").pop()?.toLowerCase() === "heic"

      // Skip validation for HEIC files on non-Apple devices
      if (
        !isAcceptedFileType(fileWithUpdatedName) &&
        !isHeicFileExtension &&
        !isUsingAppleDevice
      ) {
        setHasAcceptedFileTypeError(true)
        return { shouldProceedWithUpload: false, updatedFiles: [] }
      }

      if (fileWithUpdatedName.name.length > MaxFileNameLength) {
        setHasTooLongFileName(true)
        return { shouldProceedWithUpload: false, updatedFiles: [] }
      }

      if (
        validateInput({
          type: InputFieldType.LETTERS_NUMBERS_SPACES_DASH_UNDERSCORE_DOT,
          value: fileWithUpdatedName.name,
        }) !== ""
      ) {
        setHasValidFileName(false)
        return { shouldProceedWithUpload: false, updatedFiles: [] }
      }

      // If the file passes all checks, add it to the updatedFiles array
      updatedFiles.push(fileWithUpdatedName)
    }

    // If all files are valid, return true and the updated files
    return { shouldProceedWithUpload: true, updatedFiles }
  }

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    setFileExtensionsOnError("")
    setHasAcceptedFileTypeError(false)
    setHasTooLongFileName(false)
    setHasValidFileName(true)
    setGenericError(false)

    const files = event.target.files
    setMultiUploadFileCounter(files?.length || 1)

    if (files && files.length > 0) {
      // validate the files and update the file name (for files captured on mobile/ tablet devices)
      const { shouldProceedWithUpload, updatedFiles } = validateFilesForUpload(
        files,
        isUsingAppleDevice
      )

      let responseFiles: CaseDocument[] = []
      try {
        if (shouldProceedWithUpload) {
          setIsUploadingSingle(true)
          setIsUploading(true)
          let success = 0

          for (let i = 0; i < updatedFiles.length; i++) {
            const formData = new FormData()
            formData.append("file", updatedFiles[i], updatedFiles[i].name)

            if (workflowId && workflowVersion && caseId) {
              const responseDocument = await postUploadCaseDocument(
                workflowId,
                workflowVersion,
                caseId,
                formData
              )

              if (responseDocument) {
                success++
                responseFiles.push(responseDocument)
                setMultiUploadProgressCounter((prev) => prev + 1)
              }
            }
          }

          if (success === files.length) {
            setDocumentsToUpload(responseFiles)
            setCurrentStep(UploadWizardSteps.AddDocumentDetails)
          }
        }
      } catch (e) {
        setGenericError(true)
        setIsUploadingSingle(false)

        // Remove all files that were uploaded if one fails
        responseFiles.forEach((file) => {
          removeFile?.(file.fileId || "")
        })
      }

      setIsUploading(false)
      setUnsavedChanges?.(true)
      setIsUploadingSingle(false)
      setMultiUploadFileCounter(0)
      setMultiUploadProgressCounter(1)
    }
  }

  const handleMultiFileUpload = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setFileExtensionsOnError("")
    setHasAcceptedFileTypeError(false)
    setHasTooLongFileName(false)
    setHasValidFileName(true)
    setGenericError(false)

    const files = event.target.files
    if (files && files.length > 0) {
      let totalFilesUploadedCount = 0
      let responseFiles: IFile[] = []
      let failedFileNames: string[] = []
      let failedFileNamesSet: Set<string> = new Set()

      // show error message only if user attempts to upload only one file
      // if multiple files are uploaded, for any that fail, names will be displayed in the next step
      // there user can add more pages to the document and get an individual error message for each failed file
      for (let i = 0; i < files.length; i++) {
        try {
          // update default file name for images captured on mobile/ tablet devices
          const fileWithUpdatedName = updateDefaultNameForRecentlyCreatedImages(
            files[i]
          )

          // Validate after updating names for mobile images

          if (
            !isAcceptedFileExtension(fileWithUpdatedName, [
              ".jpeg",
              ".jpg",
              ".pdf",
              ".png",
            ])
          ) {
            if (files.length > 1) {
              failedFileNamesSet.add(fileWithUpdatedName.name)
            } else {
              setFileExtensionsOnError("JPEG, JPG, PDF or PNG")
              return
            }
          }
          if (!isAcceptedFileType(fileWithUpdatedName)) {
            if (files.length > 1) {
              failedFileNamesSet.add(fileWithUpdatedName.name)
            } else {
              setHasAcceptedFileTypeError(true)
              return
            }
          }
          if (fileWithUpdatedName.name.length > MaxFileNameLength) {
            if (files.length > 1) {
              failedFileNamesSet.add(fileWithUpdatedName.name)
            } else {
              setHasTooLongFileName(true)
              return
            }
          }
          if (
            validateInput({
              type: InputFieldType.LETTERS_NUMBERS_SPACES_DASH_UNDERSCORE_DOT,
              value: fileWithUpdatedName.name,
            }) !== ""
          ) {
            if (files.length > 1) {
              failedFileNamesSet.add(fileWithUpdatedName.name)
            } else {
              setHasValidFileName(false)
              return
            }
          }

          setIsUploadingMulti(true)
          setIsUploading(true)
          setMultiUploadFileCounter(files.length)

          // append if the file name isn't in failed failedFileNames
          const formData = new FormData()
          const isFailedFile = failedFileNamesSet.has(fileWithUpdatedName.name)
          if (!isFailedFile) {
            formData.append(
              "file",
              fileWithUpdatedName,
              fileWithUpdatedName.name
            )
          } else {
            failedFileNames.push(fileWithUpdatedName.name)
          }

          // this will need to be done one by one as the api has specific parameters
          // that are optional to pinpoint the request
          if (folderId && !isFailedFile) {
            const response = await postUploadFileToFolder({
              folderId,
              formData,
            })

            if (response && response[0]) {
              totalFilesUploadedCount++
              responseFiles.push(...response)
            }
          }
          setMultiUploadProgressCounter((prev) => prev + 1)
        } catch (e) {
          // only need to show an error if single file fails to upload as next step will show successful uploads
          if (files.length === 1) {
            setGenericError(true)
          } else {
            // push failed file names to array to pass back to parent
            failedFileNames.push(files[i].name)
          }
        }
      }

      // if at least 1 file duccessfully uploaded, proceed to next step
      if (totalFilesUploadedCount > 0) {
        setMultiPageFiles(responseFiles)
        if (failedFileNames.length > 0) {
          setFailedMultiPageFileNames?.(failedFileNames)
        }
        setCurrentStep(UploadWizardSteps.DocumentPageOverview)
      } else {
        setGenericError(true)
      }
      setIsUploadingMulti(false)
      setIsUploading(false)
      setMultiUploadProgressCounter(1)
      setMultiUploadFileCounter(0)
      setUnsavedChanges?.(true)
    }
  }

  const handleKeyPressForSingleFile = (
    event: KeyboardEvent<HTMLButtonElement>
  ) => {
    if (event.key.toLowerCase() === "enter") {
      event.preventDefault()
      !isUploadingSingleOrMulti && fileInputRef?.current?.click()
    }
  }

  const handleKeyPressForMultiFile = (
    event: KeyboardEvent<HTMLButtonElement>
  ) => {
    if (event.key.toLowerCase() === "enter") {
      event.preventDefault()
      !isUploadingSingleOrMulti && multiFileInputRef?.current?.click()
    }
  }

  return (
    <>
      <ModalHeader>Upload new file</ModalHeader>
      <ModalContent>
        <div className="upload-file-to-case">
          {uploadedViaCategory && !!requestNameFromCategory && (
            <div className="mt-3">
              <span className="upload-file-to-case-document-category">
                Document category:
              </span>
              <span>{requestNameFromCategory}</span>
            </div>
          )}
          {fileExtensionsOnError.length > 0 && (
            <>
              <AlertMessage
                className="upload-file-to-case-alert-message"
                messageType={MessageType.ERROR}
                title="There's a problem"
                message={`${alertMessageErrorsText.fileType} Please make sure all files are either ${fileExtensionsOnError}.`}
              />
              <hr className="upload-file-to-case-hr" />
            </>
          )}
          {hasAcceptedFileTypeError && (
            <>
              <AlertMessage
                className="upload-file-to-case-alert-message"
                messageType={MessageType.ERROR}
                title="There's a problem"
                message={alertMessageErrorsText.mimeType}
              />
              <hr className="upload-file-to-case-hr" />
            </>
          )}
          {hasTooLongFileName && (
            <>
              <AlertMessage
                className="upload-file-to-case-alert-message"
                messageType={MessageType.ERROR}
                title="There's a problem"
                message={alertMessageErrorsText.fileNameLength}
              />
              <hr className="upload-file-to-case-hr" />
            </>
          )}
          {!hasValidFileName && (
            <>
              <AlertMessage
                className="upload-file-to-case-alert-message"
                messageType={MessageType.ERROR}
                title="There's a problem"
                message={alertMessageErrorsText.fileName}
              />
              <hr className="upload-file-to-case-hr" />
            </>
          )}

          {genericError && (
            <>
              <AlertMessage
                className="upload-file-to-case-alert-message"
                messageType={MessageType.ERROR}
                title="There's a problem"
                message={alertMessageErrorsText.genericUpload}
              />
              <hr className="upload-file-to-case-hr" />
            </>
          )}
          <div className="upload-file-to-case-description">
            Do you have a document where each page needs to be photographic or
            scanned one by one?
          </div>
          <input
            type="file"
            id="multifile"
            className="upload-file-to-case-file-input"
            multiple
            onChange={handleMultiFileUpload}
            accept={getAllAcceptedFileTypesFromExtensions([
              ".pdf",
              ".jpeg",
              ".jpg",
              ".png",
            ])}
            ref={multiFileInputRef}
          />
          <Button
            onKeyDown={handleKeyPressForMultiFile}
            onClick={(e) => {
              e.preventDefault()
              !isUploadingSingleOrMulti && multiFileInputRef?.current?.click()
            }}
            className="upload-file-to-case-multifile-upload-button"
            variant={BUTTON_VARIANT.SECONDARY}
            ariaLabel="Upload a multi file document"
            aria-labelledby="upload-multi-file-document"
            hasAutoFocus
          >
            {!isUploadingMulti && (
              <label
                htmlFor="multifile"
                aria-labelledby="upload-multi-file-document"
              >
                Upload multi-file document
              </label>
            )}
            {isUploadingMulti && (
              <div className="upload-file-to-case-single-file-upload-button--submitting">
                <span className="mr-1">
                  Uploading {multiUploadProgressCounter} of{" "}
                  {multiUploadFileCounter} files...
                </span>
                <LoadingSpinner
                  size="20px"
                  thickness="2px"
                  color="var(--color-universal-secondary-e)"
                />
              </div>
            )}
          </Button>
          <div className="upload-file-to-case-tooltip-wrapper multifile">
            <Tooltip
              id="multifile-tooltip"
              tabIndex={0}
              aria-describedby="multifile-tooltip"
              title={tooltipMessages.multiFileMessage.title}
              message={tooltipMessages.multiFileMessage.message}
              tooltipMessageType={TooltipMessageType.List}
              icon={<QuestionMarkCircle />}
            />
            Supported file formats
          </div>
          <div className="upload-file-to-case-description">
            Do you have a document on your device where all the pages are in one
            file, or an image of one that is only one page?
          </div>
          <input
            type="file"
            id="file"
            className="upload-file-to-case-file-input"
            multiple
            onChange={handleFileUpload}
            accept={getAllAcceptedFileTypesFromExtensions([
              ".pdf",
              ".doc",
              ".docx",
              ".docm",
              ".xml",
              ".rtf",
              ".jpg",
              ".jpeg",
              ".png",
              ".heic",
              ".heif",
              ".xls",
              ".xlsx",
              ".pptx",
            ])}
            disabled={isUploadingSingle}
            ref={fileInputRef}
          />
          {/* TODO: Button component should support isBusy or isLoading prop and we should add the spinner there.  https://dev.azure.com/secure-the-file/Application/_workitems/edit/14437 */}
          <Button
            onKeyDown={handleKeyPressForSingleFile}
            onClick={(e) => {
              e.preventDefault()
              !isUploadingSingleOrMulti && fileInputRef?.current?.click()
            }}
            className="upload-file-to-case-single-file-upload-button"
            variant={BUTTON_VARIANT.SECONDARY}
            ariaLabel="Upload one or multiple single file documents"
            aria-labelledby="upload-single-file-document"
          >
            {!isUploadingSingle && (
              <label htmlFor="file" id="upload-single-file-document">
                Upload single file document(s)
              </label>
            )}
            {isUploadingSingle && (
              <div className="upload-file-to-case-single-file-upload-button--submitting">
                {multiUploadFileCounter > 1 ? (
                  <span className="mr-1">
                    Processing {multiUploadProgressCounter} of{" "}
                    {multiUploadFileCounter} files...
                  </span>
                ) : (
                  <span className="mr-1">Processing file(s)...</span>
                )}
                <LoadingSpinner
                  size="20px"
                  thickness="2px"
                  color="var(--color-universal-secondary-e)"
                />
              </div>
            )}
          </Button>
          <div className="upload-file-to-case-tooltip-wrapper single-file">
            <Tooltip
              id="file-tooltip"
              tabIndex={0}
              aria-describedby="file-tooltip"
              title={tooltipMessages.singleFileMessage.title}
              message={tooltipMessages.singleFileMessage.message}
              tooltipMessageType={TooltipMessageType.List}
              icon={<QuestionMarkCircle />}
            />
            Supported file formats
          </div>
        </div>
      </ModalContent>
    </>
  )
}
