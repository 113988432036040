import "./MenuBar.css"

import { FC, useState } from "react"
import { MenuBarItem, menuItemsBase } from "."
import {
  TLink,
  ThemeNames,
  UserModuleType,
  UserRoles,
} from "../../contexts/application/constants"
import {
  NAVMENU_NAVIGATION_INITIALSTATE,
  NavMenuNavigation,
} from "../NavMenuDropDown/constants"

import classNames from "classnames"
import { IUser } from "../../api/api-client/api-types"
import { useApplicationContext } from "../../contexts/application/context"
import { useUserContext } from "../../contexts/users"
import { isNativeMobile } from "../../utils/consts/platform"
import { castToBool } from "../../utils/helpers"
import { Button } from "../atoms/Button"
import { InvisibleBackdrop } from "../atoms/InvisibleBackdrop"
import { ShowSearchBar } from "../layouts/helper"
import { GetMenuItemsFromUserModules } from "../LeftNavigationBar/FoldersSharingNavigationItems/helpers"
import { filterMenuItemsByCurrentPage } from "../LeftNavigationBar/LeftNavigationBar"
import { SearchBarWithApplicationContext } from "../Search/SearchBarWithApplicationContext/SearchBarWithApplicationContext"
import { MenuBarSimpleItem } from "./MenuBarSimpleItem"

export interface MenuBarProps {
  menuItems: TLink[]
  isScottishWidowsTheme?: boolean
  hasSearchBar?: boolean
  selectedTheme?: string
  currentUser?: IUser
  basePath?: string
}

export const MenuButtonAndMenuPopup: FC<MenuBarProps> = ({
  menuItems,
  isScottishWidowsTheme,
}) => {
  const [vaultMenuOpen, setVaultMenuOpen] = useState(false)
  const [menuItemIsOpen, setMenuItemIsOpen] = useState("")

  const buttonClass = classNames({
    "menu-open": vaultMenuOpen,
  })

  return (
    <div className="vault-menu">
      <Button
        className={buttonClass}
        variant="menu"
        onClick={() => {
          setVaultMenuOpen(!vaultMenuOpen)
        }}
      >
        Menu
      </Button>

      {vaultMenuOpen && (
        <>
          <InvisibleBackdrop
            onClick={() => {
              setVaultMenuOpen(false)
              setMenuItemIsOpen("")
            }}
          />

          <div
            className="menu-bar-vault-box"
            style={isScottishWidowsTheme ? { right: 0 } : undefined}
          >
            <div>
              {menuItems.map((link, i) => {
                return (
                  <MenuBarItem
                    key={i}
                    link={link}
                    setVaultMenuOpen={setVaultMenuOpen}
                    menuItemIsOpen={menuItemIsOpen}
                    setMenuItemIsOpen={setMenuItemIsOpen}
                    accessibilityName={link.name.replace(" ", "")}
                  />
                )
              })}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export const MainMenuDesktop: FC<MenuBarProps> = ({
  menuItems,
  selectedTheme,
  currentUser,
  basePath,
}) => {
  const [menuItemIsOpen, setMenuItemIsOpen] = useState("")
  //keyboard navigation related
  const [navigationInfo, setNavigationInfo] = useState<NavMenuNavigation>(
    NAVMENU_NAVIGATION_INITIALSTATE
  )

  const isPowerAdviser = currentUser?.modules?.includes(
    UserModuleType.DOCUMENT_HUB
  )
  const isDocumentHubSelected = currentUser?.modules?.includes(
    UserModuleType.DOCUMENT_HUB
  )

  const isRunningInDock =
    castToBool(process.env.REACT_APP_RUNNING_IN_DOCK) ?? false
  const isOneX = selectedTheme === "ONE_X" || isRunningInDock

  const mainMenuClass = classNames("banner main-menu", {
    "oneX-hub": isDocumentHubSelected && isOneX,
  })

  return (
    <>
      {menuItemIsOpen !== "" && (
        <InvisibleBackdrop onClick={() => setMenuItemIsOpen("")} />
      )}
      {menuItems.length > 0 && (
        <nav className={mainMenuClass} data-testid="top-navigation">
          {menuItems.map((link, i) => {
            if (isDocumentHubSelected) {
              if (isPowerAdviser) {
                return (
                  <MenuBarSimpleItem link={link} key={i} basePath={basePath} />
                )
              }
            } else {
              return (
                <MenuBarItem
                  key={i}
                  link={link}
                  menuItemIsOpen={menuItemIsOpen}
                  setMenuItemIsOpen={setMenuItemIsOpen}
                  idDesktopNav={true}
                  accessibilityName={link.name}
                  navigationId={i}
                  navigationData={navigationInfo}
                  setNavigationData={setNavigationInfo}
                />
              )
            }
          })}
        </nav>
      )}
    </>
  )
}

export const MenuBar: FC<{ basePath?: string }> = ({ basePath }) => {
  const {
    applicationState: { selectedTheme },
  } = useApplicationContext()
  const {
    userState: { currentUser },
  } = useUserContext()

  const isScottishWidowsTheme = selectedTheme === "SCOTTISH_WIDOWS"
  let menuItems = GetMenuItemsFromUserModules({
    allMenuItems: menuItemsBase,
    user: currentUser,
  }) as TLink[]
  menuItems = filterMenuItemsByCurrentPage(menuItems)
  const hasSearchBar = ShowSearchBar(currentUser)
  const isAdviser = currentUser?.roles?.includes(UserRoles.Adviser)

  const isOneXHubSelected =
    selectedTheme === "ONE_X" &&
    currentUser?.modules?.includes(UserModuleType.DOCUMENT_HUB)

  const isOneXAdviserSelected =
    selectedTheme === "ONE_X" &&
    currentUser?.modules?.includes(
      UserModuleType.DOCUMENTS_LIBRARY_ADVISER_VIEW
    )

  const documentHubRelatedThemes = [
    ThemeNames.QUILTER,
    ThemeNames.ABRDN_D2C,
    ThemeNames.ABRDN_BALMORAL,
    //Abrdn Vanilla is the default abrdn theme
    ThemeNames.ABRDN_VANILLA,
    ThemeNames.ABRDN_CAPITAL,
    ThemeNames.ABRDN_LUDLOW,
    ThemeNames.ABRDN_GIBBS,
    ThemeNames.ABRDN_SUCCESSION,
    ThemeNames.ABRDN_SHERIDAN,
  ]

  const showSearchbarMobileVersion =
    selectedTheme === "ONE_LEGACY" || selectedTheme === ThemeNames.LIFESTAGE

  const legadoMenuBarClass = classNames("legado-menubar", {
    adviser: isAdviser,
    "oneX-hub": isOneXHubSelected,
  })

  const backgroundNavbarClass = classNames(
    "bar hide-on-desktop-only background-navbar",
    {
      "mobile-top-bar": isNativeMobile && isScottishWidowsTheme,
    }
  )

  return (
    <div className={legadoMenuBarClass} aria-label="MenuBar" role="main">
      <div className="container">
        {!isOneXAdviserSelected && (
          <div className="bar mainMenuDesktop-bar">
            <MainMenuDesktop
              menuItems={menuItems}
              hasSearchBar={hasSearchBar}
              selectedTheme={selectedTheme}
              currentUser={currentUser}
              basePath={basePath}
            />
            {/* TODO: This relates to a 1Legacy hot fix 14322 the search bar MenuBar has a lot of complexity and should be simplified https://dev.azure.com/secure-the-file/Application/_workitems/edit/15334*/}
            {showSearchbarMobileVersion && (
              <div className="hide-on-mobile-only">
                <SearchBarWithApplicationContext />
              </div>
            )}
          </div>
        )}
        {hasSearchBar &&
          selectedTheme &&
          !documentHubRelatedThemes.includes(selectedTheme as ThemeNames) && (
            <div className={backgroundNavbarClass}>
              <SearchBarWithApplicationContext />
            </div>
          )}
      </div>
    </div>
  )
}
