import { FC, ReactElement } from "react"
import "./LinksCollection.css"

export type LinkProps = {
  id: string
  icon?: ReactElement | null
  displayName: string
  href?: string
  onClick?: () => void
  newNotificationValue?: number
  isIconLink?: boolean // used to set styles - no fill and no underline
  ariaLabel?: string // should be provided if displayName is not provided or is not suitable for aria-label
}

export interface LinksCollectionProps {
  links: LinkProps[]
  children?: ReactElement
}

export const LinksCollection: FC<LinksCollectionProps> = ({
  links,
  children,
}) => {
  return (
    <div className="links-collection-container">
      {children}
      {links.map((link) => (
        <div
          key={link.id}
          className={`link-container ${
            link.isIconLink ? "icon-link-container" : ""
          }`}
          role="link"
          aria-label={link.ariaLabel || `Navigate to ${link.displayName}`}
        >
          {/* if displayName is valid, render icon here
          otherwise icon is clickable link and rendered within <a> or <button> below */}
          {link.displayName ? link.icon : undefined}
          <>
            {!!link.href ? (
              <a
                href={link.href}
                className={`link ${link.isIconLink && "icon-link"}`}
                tabIndex={0}
              >
                {link.displayName || link.icon}
              </a>
            ) : (
              <button
                onClick={link.onClick}
                className={`link ${link.isIconLink && "icon-link"}`}
                tabIndex={0}
              >
                {link.displayName || link.icon}
              </button>
            )}

            {link.newNotificationValue ? (
              <span className="notifications-new">
                {link.newNotificationValue}
              </span>
            ) : (
              <></>
            )}
          </>
        </div>
      ))}
    </div>
  )
}
