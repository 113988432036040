// TODO: remove this once we have the correct types
/* eslint-disable @typescript-eslint/no-explicit-any */
import ReactGa from "react-ga4"

const filterOutUndefinedParams = (eventParams: any) => {
  let definedGaEventParams: any = {}
  if (eventParams) {
    Object.keys(eventParams).forEach((param) => {
      if (eventParams[param] !== undefined)
        definedGaEventParams[param] = eventParams[param]
    })
  }
  return Object.keys(definedGaEventParams).length > 0
    ? definedGaEventParams
    : undefined
}

export const getGaEvents = (
  queueGtag: any[][]
): { name?: string; parameters?: any }[] => {
  const events = queueGtag
    .filter((queueArray) => queueArray.includes("event"))
    ?.map((eventDetails) => ({
      name: eventDetails[1],
      parameters: filterOutUndefinedParams(eventDetails[2]),
    }))
  return events
}

export const resetGaEventQueue = () => {
  ReactGa._queueGtag = []
}

export function userRolesToGARoles(role: string | undefined) {
  switch (role) {
    case "SuperAdmin":
      return "Legado Admin"
    case "Adviser":
      return "Adviser"
    case "Client":
      return "Primary User"
    case "Contact":
      return "Contact"
    case "AfterDeathContact":
      return "AfterDeathContact"
    case "ProxyContact":
      return "Contact"
    case undefined:
      return undefined
    default:
      return role
  }
}
