export const replaceSpacesWithUnderscores = (text: string) => {
  if (text) {
    return text.replace(/ /g, "_")
  }
}

export const capitalizeFirstLetter = (text: string) => {
  if (text && text.length > 0) {
    return text.charAt(0).toUpperCase() + text.slice(1)
  }
  return text
}

export const replaceNewLinesWithDotAndSpace = (text: string) => {
  return text.replace(/\n/g, ". ")
}
