export const graphDataStatic = {
  distributionProjectDateAnalyticsDtos: [
    {
      date: "2024-06-12T00:00:00Z",
      totalProjectsSent: 4,
      totalDocumentsRead: 3,
    },
    {
      date: "2024-05-27T00:00:00Z",
      totalProjectsSent: 6,
      totalDocumentsRead: 1,
    },
    {
      date: "2024-05-20T00:00:00Z",
      totalProjectsSent: 25,
      totalDocumentsRead: 0,
    },
    {
      date: "2024-05-18T00:00:00Z",
      totalProjectsSent: 12,
      totalDocumentsRead: 9,
    },
    {
      date: "2024-04-03T00:00:00Z",
      totalProjectsSent: 17,
      totalDocumentsRead: 6,
    },
    {
      date: "2024-04-10T00:00:00Z",
      totalProjectsSent: 2,
      totalDocumentsRead: 9,
    },
    {
      date: "2024-03-17T00:00:00Z",
      totalProjectsSent: 7,
      totalDocumentsRead: 0,
    },
    {
      date: "2024-02-24T00:00:00Z",
      totalProjectsSent: 1,
      totalDocumentsRead: 0,
    },
    {
      date: "2024-01-01T00:00:00Z",
      totalProjectsSent: 7,
      totalDocumentsRead: 1,
    },
    {
      date: "2024-01-08T00:00:00Z",
      totalProjectsSent: 4,
      totalDocumentsRead: 0,
    },
    {
      date: "2024-01-15T00:00:00Z",
      totalProjectsSent: 7,
      totalDocumentsRead: 0,
    },
    {
      date: "2023-11-22T00:00:00Z",
      totalProjectsSent: 0,
      totalDocumentsRead: 0,
    },
    {
      date: "2023-11-29T00:00:00Z",
      totalProjectsSent: 0,
      totalDocumentsRead: 1,
    },
    {
      date: "2023-10-05T00:00:00Z",
      totalProjectsSent: 0,
      totalDocumentsRead: 1,
    },
    {
      date: "2023-10-12T00:00:00Z",
      totalProjectsSent: 2,
      totalDocumentsRead: 0,
    },
    {
      date: "2023-10-19T00:00:00Z",
      totalProjectsSent: 1,
      totalDocumentsRead: 0,
    },
  ],
}
type GraphColorScheme = {
  yAxisLeft: string
  yAxisRight: string
  tooltipBoxShadow: string
  documentsOpened: string
  projectsSent: string
  barDocumentsOpened: string
  barProjectsSent: string
  download: string
}
export type GraphDataColors = {
  [theme: string]: GraphColorScheme
}

export const graphDataColors: GraphDataColors = {
  QUILTER: {
    yAxisLeft: "#169F50",
    yAxisRight: "#F2AC3C",
    tooltipBoxShadow: "rgba(22,22,22,0.15)",
    documentsOpened: "#169F50",
    projectsSent: "#F2AC3C",
    barDocumentsOpened: "#169F50",
    barProjectsSent: "#F2AC3C",
    download: "#169F50",
  },
  ONE_X: {
    yAxisLeft: "#179FB1",
    yAxisRight: "#E9AE65",
    tooltipBoxShadow: "rgba(22,22,22,0.15)",
    documentsOpened: "#179FB1",
    projectsSent: "#E9AE65",
    barDocumentsOpened: "#179FB1",
    barProjectsSent: "#E9AE65",
    download: "#169F50",
  },
  ABRDN_D2C: {
    yAxisLeft: "#0057B7",
    yAxisRight: "#E24082",
    tooltipBoxShadow: "rgba(22,22,22,0.15)",
    documentsOpened: "#0057B7",
    projectsSent: "#E24082",
    barDocumentsOpened: "#0057B7",
    barProjectsSent: "#E24082",
    download: "#008264",
  },
  // ABRDN VANILLA is the default abrdn theme
  ABRDN_VANILLA: {
    yAxisLeft: "#0057B7",
    yAxisRight: "#E24082",
    tooltipBoxShadow: "rgba(22,22,22,0.15)",
    documentsOpened: "#0057B7",
    projectsSent: "#E24082",
    barDocumentsOpened: "#0057B7",
    barProjectsSent: "#E24082",
    download: "#008264",
  },
  ABRDN_BALMORAL: {
    yAxisLeft: "#0057B7",
    yAxisRight: "#E24082",
    tooltipBoxShadow: "rgba(22,22,22,0.15)",
    documentsOpened: "#0057B7",
    projectsSent: "#E24082",
    barDocumentsOpened: "#0057B7",
    barProjectsSent: "#E24082",
    download: "#008264",
  },
  ABRDN_LUDLOW: {
    yAxisLeft: "#0057B7",
    yAxisRight: "#E24082",
    tooltipBoxShadow: "rgba(22,22,22,0.15)",
    documentsOpened: "#0057B7",
    projectsSent: "#E24082",
    barDocumentsOpened: "#0057B7",
    barProjectsSent: "#E24082",
    download: "#008264",
  },
  ABRDN_GIBBS: {
    yAxisLeft: "#0057B7",
    yAxisRight: "#E24082",
    tooltipBoxShadow: "rgba(22,22,22,0.15)",
    documentsOpened: "#0057B7",
    projectsSent: "#E24082",
    barDocumentsOpened: "#0057B7",
    barProjectsSent: "#E24082",
    download: "#008264",
  },
  ABRDN_SUCCESSION: {
    yAxisLeft: "#0057B7",
    yAxisRight: "#E24082",
    tooltipBoxShadow: "rgba(22,22,22,0.15)",
    documentsOpened: "#0057B7",
    projectsSent: "#E24082",
    barDocumentsOpened: "#0057B7",
    barProjectsSent: "#E24082",
    download: "#008264",
  },
  ABRDN_SHERIDAN: {
    yAxisLeft: "#0057B7",
    yAxisRight: "#E24082",
    tooltipBoxShadow: "rgba(22,22,22,0.15)",
    documentsOpened: "#0057B7",
    projectsSent: "#E24082",
    barDocumentsOpened: "#0057B7",
    barProjectsSent: "#E24082",
    download: "#008264",
  },

  DEFAULT: {
    yAxisLeft: "#169F50",
    yAxisRight: "#F2AC3C",
    tooltipBoxShadow: "rgba(22,22,22,0.15)",
    documentsOpened: "#169F50",
    projectsSent: "#F2AC3C",
    barDocumentsOpened: "#169F50",
    barProjectsSent: "#F2AC3C",
    download: "#169F50",
  },
}
