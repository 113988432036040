import { FC } from "react"
import Search from "../../../../design-tokens/icons/theme/oneX/Search"
import { useGetIcon } from "../../../../styled-components/GetIconLibraryInTheme"
import { ProfileButton } from "../../ProfileButton/ProfileButton"
import "./TopBarButtonsCollection.css"

export interface TopBarButtonsCollectionProps {
  Logo: JSX.Element
  onSearchClick?: () => void
  onHamburgerClick?: () => void
  //TODO: Remove this once we have a working search bar
  shouldShowSearch?: boolean
}

export const TopBarButtonsCollection: FC<TopBarButtonsCollectionProps> = ({
  Logo,
  onSearchClick,
  onHamburgerClick,
  shouldShowSearch = true,
}) => {
  const HamburgerIcon = useGetIcon("Hamburger")

  return (
    <div className="top-bar-content">
      <div className="logo-container">{Logo}</div>
      <div className="top-bar-buttons-container">
        {shouldShowSearch && (
          <button
            className="search-button link"
            onClick={onSearchClick}
            aria-label="Search"
          >
            <Search />
          </button>
        )}
        <button
          className="hamburger-button link"
          onClick={onHamburgerClick}
          aria-label="Menu"
        >
          {HamburgerIcon}
        </button>
        <ProfileButton />
      </div>
    </div>
  )
}
