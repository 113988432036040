import { ComponentVariablesTypes } from "../../../tier3-component-specific-variables/bridgeTokenTypes"
import boxStylingTokens from "../tier1-definitions/boxStyles.json"
import colorTokens from "../tier1-definitions/colors.json"

/**
 * Link to design tokens on figma, for this theme:
 * https://www.figma.com/file/Evp203H09SnUhprfWxg9P5/abrdn%3A-Theme?type=design&node-id=16821-1530&mode=design&t=1O3jq10RWr3Xpu7l-0
 */

export const applicationTokens = {
  boxStyles: {
    //radius

    boxStyleActionBorderRadiusSmall: boxStylingTokens.radius.small[0].value,
    boxStyleActionBorderRadiusLarge: boxStylingTokens.radius.small[0].value,
    boxStyleActionBorderRadiusButton: boxStylingTokens.radius.button[0].value,
    boxStyleActionBorderRadius5px: "5px",
    //border

    boxStyleActionBorderPrimary: boxStylingTokens.borders[0].value,

    // shadow

    actionShadowPrimary: "none",

    headerShadow: "0px 1px 0px 0px #a1a1a1",

    documentShadow: "0px 2px 10px 0px #00000026",
  },
}

// Bridge Tokens from Tier 2 to Tier 3
// This object is used for mapping theme-related variables from Tier 2 to Tier 3 and
// should be setup in each theme. This is so the T3 componentVariables object in componentVariables.ts
// can remain the same and be reused across the themes.
export const bridgeTokensT2ToT3: ComponentVariablesTypes = {
  universal: {
    colors: {
      // Action colors
      componentColorActionBackgroundDisabledT2Token:
        colorTokens["button-disabled-base"],
      componentColorActionBorderDisabledT2Token:
        colorTokens["button-disabled-base"],
      componentColorActionTextDisabledT2Token:
        colorTokens["button-disabled-text"],

      // Feedback colors
      componentColorFeedbackSuccessT2Token:
        colorTokens["functional-success-base"],
      componentColorFeedbackSuccessHoverT2Token:
        colorTokens["functional-message-hover"],
      componentColorFeedbackSuccessShadeT2Token:
        colorTokens["functional-success-shade"],
      componentColorFeedbackWarningT2Token:
        colorTokens["functional-warning-base"],
      componentColorFeedbackWarningHoverT2Token:
        colorTokens["functional-warning-hover"],
      componentColorFeedbackWarningShadeT2Token:
        colorTokens["functional-warning-shade"],
      componentColorFeedbackDangerT2Token:
        colorTokens["functional-danger-base"],
      componentColorFeedbackDangerHoverT2Token:
        colorTokens["functional-danger-hover"],
      componentColorFeedbackDangerShadeT2Token:
        colorTokens["functional-danger-shade"],
      componentColorFeedbackMessageT2Token:
        colorTokens["functional-message-base"],
      componentColorFeedbackMessageHoverT2Token:
        colorTokens["functional-message-hover"],
      componentColorFeedbackMessageShadeT2Token:
        colorTokens["functional-message-shade"],
      // Interaction colors
      componentColorInteractionBackgroundPrimaryHoverT2Token:
        colorTokens["hover-base"],
      componentColorInteractionBackgroundSecondaryHoverT2Token:
        colorTokens["hover-secondary"],
      componentColorInteractionBackgroundRemoveHoverT2Token:
        colorTokens["hover-remove"],
      componentColorInteractionBorderHoverT2Token: colorTokens["hover-outline"],

      // Brand colors
      colorUniversalNaturalBlackT2Token: colorTokens["neutral-black"],
      colorUniversalNaturalWhiteT2Token: colorTokens["neutral-white"],
      colorUniversalNaturalGreyT2Token: colorTokens["neutral-grey"],
      colorUniversalSecondaryAT2Token: colorTokens["secondary-a"],
      colorUniversalSecondaryBT2Token: colorTokens["secondary-b"],
      colorUniversalSecondaryCT2Token: colorTokens["secondary-c"],
      colorUniversalSecondaryDT2Token: colorTokens["secondary-d"],
      colorUniversalSecondaryET2Token: colorTokens["secondary-e"],
      colorUniversalSecondaryFT2Token: colorTokens["secondary-f"],
      colorUniversalSecondaryGT2Token: colorTokens["secondary-g"],
      colorUniversalSecondaryHT2Token: colorTokens["secondary-h"],

      colorUniversalToneAT2Token: colorTokens["tone-a-100"],
      colorUniversalToneA25T2Token: colorTokens["tone-a-25"],
      colorUniversalPrimaryBlackT2Token: colorTokens["primary-black"],
      colorUniversalPrimaryGreyT2Token: colorTokens["primary-grey"],
      colorUniversalPrimaryBackgroundT2Token: colorTokens["primary-background"],
      colorUniversalPrimaryBaseT2Token: colorTokens["primary-base"],
      cardColorContextBackgroundPrimaryT2Token: colorTokens["background-card"],
      colorDocumentHubComponentBackgroundT2Token: colorTokens["neutral-white"],
    },
  },
  accordion: {
    colors: {
      background: {
        accordionColorContextBackgroundSectionActiveT2Token:
          colorTokens["background-accordion-section-active"],
      },
    },
  },
  button: {
    colors: {
      // Button background color
      buttonColorActionBackgroundPrimaryT2Token:
        colorTokens["button-primary-base"],
      buttonColorActionBackgroundPrimaryHoverT2Token:
        colorTokens["button-primary-hover"],
      buttonColorActionBackgroundSecondaryT2Token:
        colorTokens["button-secondary-base"],
      buttonColorActionBackgroundSecondaryHoverT2Token:
        colorTokens["button-secondary-hover"],
      buttonColorActionBackgroundTertiaryT2Token:
        colorTokens["button-tertiary-base"],
      buttonColorActionBackgroundTertiaryHoverT2Token:
        colorTokens["button-tertiary-hover"],
      buttonColorActionBackgroundDangerT2Token:
        colorTokens["button-danger-base"],
      buttonColorActionBackgroundDangerHoverT2Token:
        colorTokens["button-danger-hover"],
      buttonColorActionBackgroundRemoveT2Token:
        colorTokens["button-danger-outline-base"],
      buttonColorActionBackgroundRemoveHoverT2Token:
        colorTokens["button-danger-hover"],
      buttonColorActionBackgroundDisabledT2Token:
        colorTokens["button-disabled-base"],

      // Text color
      buttonColorActionTextPrimaryT2Token: colorTokens["button-primary-text"],
      buttonColorActionTextPrimaryHoverT2Token:
        colorTokens["button-primary-hovertext"],
      buttonColorActionTextSecondaryT2Token:
        colorTokens["button-secondary-text"],
      buttonColorActionTextSecondaryHoverT2Token:
        colorTokens["button-secondary-hovertext"],
      buttonColorActionTextTertiaryT2Token: colorTokens["button-tertiary-text"],
      buttonColorActionTextTertiaryHoverT2Token:
        colorTokens["button-tertiary-hovertext"],
      buttonColorActionTextDangerT2Token: colorTokens["button-danger-text"],
      buttonColorActionTextDangerHoverT2Token:
        colorTokens["button-danger-hovertext"],
      buttonColorActionTextRemoveT2Token:
        colorTokens["button-danger-outline-text"],
      buttonColorActionTextRemoveHoverT2Token:
        colorTokens["button-danger-outline-hovertext"],
      buttonColorActionTextDisabledT2Token: colorTokens["button-disabled-text"],
    },
    boxStyles: {
      // Border radius
      buttonActionBorderRadiusButtonXT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderRadiusButton,
      // Border
      buttonActionBorderPrimaryT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderPrimary?.concat(
          colorTokens["button-primary-outline"] ?? "Not set"
        ),
      buttonActionBorderPrimaryHoverT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderPrimary?.concat(
          colorTokens["button-primary-hover"] ?? "Not set"
        ),
      buttonActionBorderSecondaryT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderPrimary?.concat(
          colorTokens["button-secondary-outline"] ?? "Not set"
        ),
      buttonActionBorderSecondaryHoverT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderPrimary?.concat(
          colorTokens["button-secondary-hover"] ?? "Not set"
        ),
      buttonActionBorderTertiaryT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderPrimary?.concat(
          colorTokens["button-tertiary-outline"] ?? "Not set"
        ),
      buttonActionBorderTertiaryHoverT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderPrimary?.concat(
          colorTokens["button-primary-hover"] ?? "Not set"
        ),
      buttonActionBorderDangerT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderPrimary?.concat(
          colorTokens["button-danger-outline"] ?? "Not set"
        ),
      buttonActionBorderDangerHoverT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderPrimary?.concat(
          colorTokens["button-danger-hover"] ?? "Not set"
        ),
      buttonActionBorderRemoveT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderPrimary?.concat(
          colorTokens["button-danger-outline-stroke"] ?? "Not set"
        ),
      buttonActionBorderRemoveHoverT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderPrimary?.concat(
          colorTokens["button-danger-outline-hover"] ?? "Not set"
        ),
      // Box shadow
      buttonActionShadowPrimaryT2Token:
        applicationTokens.boxStyles.actionShadowPrimary,
      buttonActionShadowPrimaryHoverT2Token:
        applicationTokens.boxStyles.actionShadowPrimary,
      buttonActionShadowSecondaryT2Token:
        applicationTokens.boxStyles.actionShadowPrimary,
      buttonActionShadowSecondaryHoverT2Token:
        applicationTokens.boxStyles.actionShadowPrimary,
      buttonActionShadowTertiaryT2Token:
        applicationTokens.boxStyles.actionShadowPrimary,
      buttonActionShadowTertiaryHoverT2Token:
        applicationTokens.boxStyles.actionShadowPrimary,
    },
  },
  breadcrumb: {
    colors: {
      breadcrumbColorInteractionTextPrimaryT2Token:
        colorTokens["action-breadcrumb-base"],
      breadcrumbColorInteractionTextPrimaryHoverT2Token:
        colorTokens["action-breadcrumb-hover"],
    },
  },
  card: {
    colors: {
      border: {
        cardColorContextBorderLighterT2Token:
          colorTokens["outline-card-lighter"],
        cardColorContextBorderDarkerT2Token: colorTokens["outline-card-darker"],
      },
    },
  },
  divider: {
    colors: {
      background: {
        dividerColorContextBackgroundPrimaryT2Token:
          colorTokens["background-divider"],
        dividerColorContextBackgroundLighterT2Token:
          colorTokens["background-divider-lighter"],
      },
    },
  },
  dragAndDrop: {
    colors: {
      background: {
        dragAndDropInteractionColorBackgroundT2Token:
          colorTokens["background-dragdrop"],
      },
    },
    boxStyles: {
      dragAndDropInteractionBorderRadiusT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderRadius5px,
    },
  },
  tabBar: {
    colors: {
      tabBarColorInteractionTextFocusT2Token: colorTokens["tab-text-focus"],
      tabBarColorInteractionTextInactiveT2Token:
        colorTokens["tab-text-inactive"],
      tabBarColorInteractionTabLineFocusT2Token: colorTokens["tab-line-focus"],
      tabBarColorInteractionTabLineInactiveT2Token:
        colorTokens["tab-line-inactive"],
    },
  },

  table: {
    colors: {
      tableColorContextBackgroundLabelT2Token:
        colorTokens["background-table-label"],
    },
  },
  tag: {
    colors: {
      // Background color
      tagColorInteractionBackgroundPrimaryT2Token:
        colorTokens["action-tag-base"],
      tagColorInteractionBackgroundPrimaryHoverT2Token:
        colorTokens["action-tag-hover"],
      tagColorInteractionBackgroundPrimaryFocusT2Token:
        colorTokens["action-tag-focus-filled"],

      // Text color
      tagColorInteractionTextT2Token: colorTokens["action-tag-text"],
      tagColorInteractionTextHoverT2Token: colorTokens["action-tag-hovertext"],
      tagColorInteractionTextFocusT2Token: colorTokens["action-tag-focus-text"],

      // border color
      tagColorInteractionBorderPrimaryT2Token:
        colorTokens["action-tag-outline"],
      tagColorInteractionBorderPrimaryFocusT2Token:
        colorTokens["action-tag-focus-outline"],
    },
    boxStyles: {
      // radius
      borderRadiusT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderRadiusLarge,
    },
  },
  toggle: {
    colors: {
      toggleColorInteractionInteractiveT2Token: colorTokens["toggle-inactive"],
      toggleColorInteractionFocusT2Token: colorTokens["toggle-focus"],
    },
  },
  topBar: {
    colors: {
      topBarColorInteractionBackgroundPrimaryT2Token:
        colorTokens["background-page-adviser"],
    },
  },
  search: {
    colors: {
      // Background color
      searchColorInteractionBackgroundPrimaryT2Token:
        colorTokens["search-background"],
      searchColorInteractionBackgroundPrimaryHoverT2Token:
        colorTokens["search-background-hover"],
      // Chevron color
      searchColorInteractionChevronT2Token: "not set",

      // Text color
      searchColorInteractionTextPrimaryT2Token: colorTokens["search-content"],
      searchColorInteractionTextPrimaryFocusT2Token:
        colorTokens["search-content-focus"],

      // border color
      searchColorInteractionBorderPrimaryT2Token: colorTokens["search-outline"],
      searchColorInteractionBorderPrimaryHoverT2Token:
        colorTokens["search-outline-hover"],
      searchColorInteractionBorderPrimaryFocusT2Token:
        colorTokens["search-outline-focus"],
    },
    boxStyles: {
      searchRadiusT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderRadius5px,
    },
  },
  footer: {
    colors: {
      footerColorContextBackgroundPrimaryT2Token:
        colorTokens["background-footer"],
    },
  },
  form: {
    colors: {
      // Background color
      formColorInteractionBackgroundPrimaryT2Token:
        colorTokens["form-background"],
      formColorInteractionBackgroundPrimaryHoverT2Token:
        colorTokens["form-background-hover"],

      // Text color
      formColorInteractionTextPrimaryT2Token: colorTokens["form-content"],
      formLabelColorInteractionTextPrimaryT2Token:
        colorTokens["form-content-label"],
      formColorInteractionTextPrimaryFocusT2Token:
        colorTokens["form-content-focus"],

      // border color
      formColorInteractionBorderPrimaryT2Token: colorTokens["form-outline"],
      formColorInteractionBorderPrimaryHoverT2Token:
        colorTokens["form-outline-hover"],
      formColorInteractionBorderPrimaryFocusT2Token:
        colorTokens["form-outline-focus"],
    },
    boxStyles: {
      formColorInteractionBorderPrimaryFocusT2Token: "not set",
    },
  },
  lozenge: {
    colors: {
      // Background color
      lozengeColorFeedbackBackgroundPrimaryT2Token:
        colorTokens["lozenge-default-base"],
      lozengeColorFeedbackBackgroundPendingT2Token:
        colorTokens["lozenge-pending-base"],
      lozengeColorFeedbackBackgroundPositiveT2Token:
        colorTokens["lozenge-positve-base"],
      lozengeColorFeedbackBackgroundInfoT2Token:
        colorTokens["lozenge-info-base"],
      lozengeColorFeedbackBackgroundEmphasizedT2Token:
        colorTokens["lozenge-emphasized-base"],
      lozengeColorFeedbackBackgroundNegativeT2Token:
        colorTokens["lozenge-negative-base"],
      lozengeColorFeedbackBackgroundRevokeT2Token:
        colorTokens["lozenge-revoke-base"],
      lozengeColorFeedbackBackgroundMultipleT2Token:
        colorTokens["lozenge-multiple-base"],

      // Text color
      lozengeColorFeedbackTextPrimaryT2Token:
        colorTokens["lozenge-default-text"],
      lozengeColorFeedbackTextPendingT2Token:
        colorTokens["lozenge-pending-text"],
      lozengeColorFeedbackTextNegativeT2Token:
        colorTokens["lozenge-negative-text"],
      lozengeColorFeedbackTexRevokeT2Token: colorTokens["lozenge-revoke-text"],
      lozengeColorFeedbackTexInfoT2Token: colorTokens["lozenge-info-text"],
      lozengeColorFeedbackTexPositiveT2Token:
        colorTokens["lozenge-positive-text"],
      lozengeColorFeedbackTexEmphasizedT2Token:
        colorTokens["lozenge-emphasized-text"],
      lozengeColorFeedbackTexMultipleT2Token:
        colorTokens["lozenge-multiple-text"],

      // Border color
      lozengeColorFeedbackBorderPrimaryT2Token:
        colorTokens["lozenge-default-outline"],
      lozengeColorFeedbackBorderPendingT2Token:
        colorTokens["lozenge-pending-outline"],
      lozengeColorFeedbackBorderPositiveT2Token:
        colorTokens["lozenge-positive-outline"],
      lozengeColorFeedbackBorderNegativeT2Token:
        colorTokens["lozenge-negative-outline"],
      lozengeColorFeedbackBorderRevokeT2Token:
        colorTokens["lozenge-revoke-outline"],
      lozengeColorFeedbackBorderInfoT2Token:
        colorTokens["lozenge-info-outline"],
      lozengeColorFeedbackBorderEmphasizedT2Token:
        colorTokens["lozenge-emphasized-outline"],
      lozengeColorFeedbackBorderMultipleT2Token:
        colorTokens["lozenge-multiple-outline"],
    },
  },
  link: {
    colors: {
      linkColorActionTextLineT2Token: colorTokens["action-link-line"],
      linkColorActionTextPrimaryT2Token: colorTokens["action-link-base"],
      linkColorActionTextPrimaryHoverT2Token: colorTokens["action-link-hover"],
    },
  },
  literatureAndGuides: {
    colors: {
      literatureAndGuidesColorBackgroundPrimaryT2Token:
        colorTokens["background-modal"],
    },
  },
  modal: {
    colors: {
      modalColorContextBackgroundPrimaryT2Token:
        colorTokens["background-modal"],
    },
  },
  navbar: {
    colors: {
      navbarColorInteractionBackgroundPrimaryT2Token:
        colorTokens["navbar-background"],
      navbarColorInteractionBackgroundExpandT2Token:
        colorTokens["navbar-background-expand"],
      navbarColorInteractionTextPrimaryT2Token: colorTokens["navbar-text"],
      navbarColorInteractionBackgroundActiveT2Token:
        colorTokens["navbar-background-active"],
      navbarColorInteractionBorderT2Token: colorTokens["background-divider"],
    },
  },
  page: {
    colors: {
      pageColorContextBackgroundPrimaryT2Token:
        colorTokens["background-page-adviser"],
      pageColorContextBackgroundSecondaryT2Token:
        colorTokens["background-page"],
    },
  },
  pagination: {
    colors: {
      // Background color
      paginationColorInteractionBackgroundPrimaryT2Token:
        colorTokens["pagination-background-base"],
      paginationColorInteractionBackgroundPrimaryFocusT2Token:
        colorTokens["pagination-background-focus"],

      // border color
      paginationColorInteractionBorderPrimaryT2Token:
        colorTokens["pagination-outline"],
      // Text color
      paginationColorInteractionTextPrimaryT2Token:
        colorTokens["pagination-text"],
      paginationColorInteractionTextPrimaryFocusT2Token:
        colorTokens["pagination-text-focus"],
      // Chevron color
      paginationColorInteractionChevronT2Token:
        colorTokens["pagination-chevron"],
    },
  },
  panel: {
    colors: {
      panelColorBackgroundPrimaryT2Token: "not set",
    },
    boxStyles: {
      boxStyleActionBorderRadiusSmallT2Token: "not set",
      panelShadowPrimary: "not set",
      actionShadowPrimaryT2Token: "not set",
    },
  },
  profileButton: {
    colors: {
      profileButtonColorTextT2Token: colorTokens["secondary-e"],

      // Background color
      profileButtonColorBackgroundT2Token: colorTokens["button-primary-base"],
    },
    outlines: {
      profileButtonOutlineT2Token: `3px solid ${colorTokens["outline-card-lighter"]}`,
      profileButtonOutlineOffsetT2Token: "0px",
    },
  },
  results: {
    colors: {
      resultsColorInteractionBackgroundPrimaryT2Token:
        colorTokens["results-background-base"],
      // Background color
      resultsColorInteractionBackgroundChevronT2Token:
        colorTokens["results-background-chevron"],
      // Chevron color
      resultsColorInteractionChevronT2Token: colorTokens["results-chevron"],
      // border color
      resultsColorInteractionBorderPrimaryT2Token:
        colorTokens["results-outline"],
    },
  },
}
