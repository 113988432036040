import { IFile, IFolder } from "../../api/api-client/api-types"
import {
  TSearchResult,
  TSearchTagResult,
} from "../../contexts/application/constants"

const doesInclude = (name: string, expr: string) => {
  const lowerCase = name.toLowerCase()
  if (lowerCase.includes(expr.toLowerCase())) {
    return true
  }
}

const searchFolder = ({
  folder,
  expression,
  searchResult,
}: {
  folder: IFolder
  expression: string
  searchResult: TSearchResult
}) => {
  folder.contents?.forEach((file) => {
    if (doesInclude(file.name, expression)) {
      searchResult.files.push(file)
    }
  })
  folder.childFolders?.forEach((f) => {
    if (doesInclude(f.name, expression)) {
      searchResult.folders.push(f)
    }
    searchFolder({ folder: f, expression, searchResult })
  })
}

export const searchFoldersAndFiles = ({
  expression,
  folders,
}: {
  expression: string
  folders: IFolder[]
}) => {
  const searchResult = {
    expression,
    folders: [] as IFolder[],
    files: [] as IFile[],
    tags: [] as TSearchTagResult[],
  } as TSearchResult
  expression = expression.toLowerCase().trim()
  folders?.forEach((folder) => {
    if (folder.name?.toLowerCase().includes(expression)) {
      searchResult.folders.push(folder)
    }
    searchFolder({ folder, expression, searchResult })
  })
  return searchResult
}

export const searchFoldersAndFilesByIds = ({
  ids,
  folders,
}: {
  ids: string[]
  folders: IFolder[]
}) => {
  const searchResult = {
    expression: "",
    folders: [] as IFolder[],
    files: [] as IFile[],
    tags: [] as TSearchTagResult[],
  } as TSearchResult
  folders.forEach((folder) => {
    folder.contents?.forEach((file) => {
      if (ids.includes(file.id)) {
        searchResult.files.push(file)
      }
    })
    folder.childFolders?.forEach((f) => {
      if (ids.includes(f.id)) {
        searchResult.folders.push(f)
      }
      searchFolderByIds({ folder: f, ids, searchResult })
    })
  })
  return searchResult
}

const searchFolderByIds = ({
  folder,
  ids,
  searchResult,
}: {
  folder: IFolder
  ids: string[]
  searchResult: TSearchResult
}) => {
  folder.contents?.forEach((file) => {
    if (ids.includes(file.id)) {
      searchResult.files.push(file)
    }
  })
  folder.childFolders?.forEach((f) => {
    if (ids.includes(f.id)) {
      searchResult.folders.push(f)
    }
    searchFolderByIds({ folder: f, ids, searchResult })
  })
}
