// TODO: remove this once we have the correct types
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from "axios"
import {
  AccountDetailDto,
  ActivityApi,
  ActivityLogEntryDto,
  AdviserApi,
  AuthApi,
  ClientInviteDto,
  ConnectivityApi,
  CreateReminderDto,
  FeatureApi,
  FileImportantRecentDto,
  FilesWithTagsDto,
  FlatFileNodeDto,
  FolderDto,
  FolderTreeNodeDto,
  Industry,
  NodeApi,
  NodeDto,
  NotificationApi,
  NotificationDto,
  OrgAdviserDto,
  OrganisationApi,
  OrganisationsUsersDto,
  Provider,
  ProviderAccountDto,
  PubSubApi,
  PubSubNotificationDto,
  ReminderApi,
  ReminderDto,
  SharedVaultDto,
  SharingApi,
  SuggestedProviderDto,
  UpdateReminderDto,
  UploadApi,
  UserApi,
  UserDto,
} from "legado-generated-api-client"
import {
  ContactDto,
  ContactInviteDto,
  CreateFolderDto,
  FeatureSetDto,
  FileNodeDto,
  NodeShareDto,
} from "legado-generated-api-client/models"
import { Dispatch } from "react"
import { v4 as uuidv4 } from "uuid"
import { GetLocalFolderConnectionStatus } from "../component/Connectivity/HomePageBanners/data"
import { TDate } from "../component/ReviewSection/DateReview/DateReview"
import {
  base64TestThumbnail,
  base64TestThumbnailLandscape,
} from "../component/atoms/Thumbnail/data"
import { ParseWebhookMessage } from "../component/helpers/ParseWebhookMessage"
import { LEGADO_REMINDER_APPLICATION_ID } from "../contexts/application/constants"
import { LocalStorageKeys } from "../contexts/auth/localStorageKeys"
import { IThumbnailAction } from "../contexts/thumbnails/reducer"
import { IToastAction } from "../contexts/toasts/reducer"
import { notificationsData } from "../pages/NotificationsPage/data"
import { loginTypes } from "../utils/auth/loginTypes"
import { sleep, triggerLinkDownload } from "../utils/helpers"
import { createWebHook } from "../utils/websocket/websocketUtils"
import { getIndustriesFromLocalData } from "./ApiControllersMock/ApiDataConfigMock/getters/getIndustries"
import { getSuggestedFoldersFromLocalData } from "./ApiControllersMock/ApiDataConfigMock/getters/getSuggestedFolders"
import {
  getLocalProviderAccountInfo,
  getProviderFolderName,
} from "./api-client/api-handler"
import {
  IContactInvite,
  IFile,
  IFolder,
  IUser,
  ReviewResponse,
} from "./api-client/api-types"
import { ApiConnection } from "./api-client/common/ApiConnection"
import { getPdfBase64 } from "./staticData/getPdfBase64"
import { getPdfPreviewResponse } from "./staticData/getPdfPreviewResponse"

export class ApiController {
  protected static instance: ApiController
  protected apiConnection!: ApiConnection
  protected baseUrl: string | undefined
  protected token: string | undefined
  protected localUserEmail: string | undefined
  // Indicates that we have a token to make requests to authenticated endpoints
  protected _connected: boolean = false
  protected _thirdPartywebSocket: WebSocket | undefined
  protected _cachedCounter = 0
  protected _shouldAutoRefreshToken: boolean = true

  private authExpiryTime = +(process.env.API_TOKEN_EXPIRY_MINUTES ?? 15)

  public get connected() {
    return this._connected
  }
  public get getApiConnection() {
    return this.apiConnection
  }
  public get getApiToken() {
    return this.token
  }
  public get getBaseUrl() {
    return this.baseUrl
  }

  public setAutoRefreshToken(value: boolean) {
    this._shouldAutoRefreshToken = value
  }

  public handleTimeout = (isTimedOut: boolean) => {}

  /**
   * The ApiController's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  protected constructor() {
    this.baseUrl = process.env.REACT_APP_API_URL
  }

  /**
   * The static method that controls the access to the ApiController instance.
   *
   * This implementation let you subclass the ApiController class while keeping
   * just one instance of each subclass around.
   */
  public static getInstance(): ApiController {
    if (!ApiController.instance) {
      ApiController.instance = new ApiController()
    }

    return ApiController.instance
  }

  public setToken(externalToken: string) {
    console.log("Set External Token")
    localStorage.setItem(LocalStorageKeys.Token, externalToken)
    this.token = externalToken
  }

  public async setLocalUserEmail(userEmail: string) {
    this.localUserEmail = userEmail
  }

  public async addTag(
    fileId: string,
    tagName: string,
    secondAttempt: boolean = false
  ): Promise<Boolean> {
    if (this._connected) {
      try {
        const nodeApi = new NodeApi({
          basePath: this.baseUrl,
        })

        const res = await nodeApi.apiNodeFileAddTagFileIdPut(
          fileId,
          tagName,
          this.getApiHeader(true)
        )
        return res.status === 200
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not add file tag",
          secondAttempt
        )
        if (shouldRetry) {
          return await this.addTag(fileId, tagName, true)
        }
        return false
      }
    }
    return true
  }

  /**
   * Sets the apiConnection
   * Sets the token via doTokenRefresh() method or via apiAuthTokenbyemailGet method (Only for testing purposes, not in prod)
   * Sets the _connected flag to true if the token is defined, false otherwise.
   * Sets an interval to refresh a token every 14 minutes via setIntervalRefreshBeforeExpiry
   * @param apiConnection ApiConnection instance
   * @returns true if apiConnection is defined and apiKey is defined and the token is defined
   * @returns true if apiKey is defined and baseUrl is defined and the token is defined after a token refresh
   * @returns true if apiKey is defined and baseUrl is defined and the token is defined after apiAuthTokenbyemailGet method succeeded
   * @returns false if apiConnection is not defined or apiKey is not defined or baseUrl is not defined
   * or the token is not defined (doTokenRefresh failed or apiAuthTokenbyemailGet failed)
   */
  public async setApiConnection(apiConnection?: ApiConnection) {
    if (apiConnection && apiConnection.apiKey) {
      this.apiConnection = apiConnection
      if (this.token) {
        this._connected = true
        return true
      }

      if (this.apiConnection.apiKey && this.baseUrl) {
        try {
          const savedRefreshTokenCode = localStorage.getItem(
            LocalStorageKeys.RefreshToken
          )
          if (
            this.apiConnection.refreshTokenCode !== null ||
            savedRefreshTokenCode !== null
          ) {
            await this.doTokenRefresh()
          } else if (this.apiConnection.apiUsername && !this.token) {
            await this.setTokenForTesting()
          }
          if (this._shouldAutoRefreshToken) {
            this.setIntervalRefreshBeforeExpiry()
          }
        } catch (error) {
          console.error(error)
          this.token = undefined
          this.apiConnection.failed = true
        }
      } else {
        this.token = undefined
      }

      if (this.token) {
        this._connected = true
        return true
      }
    }

    this.apiConnection = new ApiConnection(undefined, undefined)
    this.token = undefined
    this._connected = false
    this.apiConnection.failed = true
    return false
  }

  //Calls refresh one minute before expiry
  private async setIntervalRefreshBeforeExpiry() {
    if (!this.apiConnection.failed) {
      let msToCall = (this.authExpiryTime - 1) * 60000
      const api = ApiController.getInstance()
      setInterval(() => api.doTokenRefresh(), msToCall)
    }
  }

  private async setTokenForTesting() {
    const authApi = new AuthApi({ basePath: this.baseUrl })
    const secondAttempt = false
    // This api endpoint is not available in production. It is only for testing purposes.
    const response = await authApi.apiAuthTokenbyemailGet(
      this.apiConnection.apiUsername,
      this.authExpiryTime,
      secondAttempt,
      this.getApiHeader(true)
    )
    this.token = response.data
    console.log("Got Internal Token only for testing purposes")
    localStorage.setItem(LocalStorageKeys.Token, this.token)
  }

  /**
  Feature Controller
  */
  public async hasFeature(featureName: string) {
    return true
  }

  public async getFeatureSet(enabledOnly?: boolean): Promise<FeatureSetDto> {
    if (this.connected) {
      try {
        const nodeApi = new FeatureApi({
          basePath: this.baseUrl,
        })
        const response = await nodeApi.apiFeatureGetFeatureSetGet(
          enabledOnly,
          this.getApiHeader(true)
        )
        if (response) {
          return response.data
        }
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get feature set",
          true
        )
        if (shouldRetry) {
          return await this.getFeatureSet(enabledOnly)
        }
        return {
          featureDtos: [],
          theme: null,
          loginMethod: null,
        }
      }
    }
    return {
      featureDtos: [],
      theme: null,
      loginMethod: null,
    }
  }

  // The user does not have to be connected (authenticated) to get the feature list for a platform (identified by the API key or origin header)
  public async getAllFeatures() {
    const nodeApi = new FeatureApi({
      basePath: this.baseUrl,
    })
    // We do this because ApiKey may be provided through querystring - if not then platform is determined by origin header.
    // use case: Testing with localhost.
    // use case: dev/qa environments.
    // use case: The app is hosted in the "MiddleEnd".
    const apiKey =
      new URLSearchParams(window.location.search).get("apikey") ?? ""

    // use case: The app is use with "Password" Login method (example: COOP)
    let apiKeyFromLocalStorage
    if (apiKey === "" || apiKey === null) {
      apiKeyFromLocalStorage = localStorage.getItem(LocalStorageKeys.ApiKey)
    }

    const response = await nodeApi.apiFeatureGetAllFeaturesGet(true, {
      headers: {
        apiKey: apiKey || (apiKeyFromLocalStorage ?? ""),
      },
    })

    return response.data
  }

  /**
   * Node API
   */
  public async getFolders(secondAttempt: boolean = false): Promise<NodeDto[]> {
    if (this._connected) {
      try {
        const nodeApi = new NodeApi({
          basePath: this.baseUrl,
        })

        const response = await nodeApi.apiNodeGetNodeListGet(
          true,
          undefined,
          undefined,
          this.getApiHeader(true)
        )
        return response.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get folders",
          secondAttempt
        )
        if (shouldRetry) {
          return await this.getFolders(true)
        }
        return []
      }
    } else {
      // TODO: change to throw new Error("Do not use static data, use MSW instead")
      console.error("Do not use static data, use MSW instead")
      return []
    }
  }

  public async getFoldersSharedByUser(
    userId: string,
    secondAttempt: boolean = false
  ): Promise<SharedVaultDto> {
    if (this._connected) {
      try {
        const sharingApi = new SharingApi({
          basePath: this.baseUrl,
        })
        const response = await sharingApi.apiSharingUsersSharerIdGet(
          userId,
          this.getApiHeader(true)
        )
        return response.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get folders for user",
          secondAttempt
        )
        if (shouldRetry) return await this.getFoldersSharedByUser(userId, true)

        return [] as SharedVaultDto
      }
    } else {
      throw new Error("Do not use static data, use MSW instead")
    }
  }

  public async getAdviserFilesForClientAccount(
    accountId: string
  ): Promise<SharedVaultDto> {
    if (this._connected) {
      try {
        const adviserApi = new AdviserApi({
          basePath: this.baseUrl,
        })
        const response = await adviserApi.apiAdviserAccountAccountIdGet(
          accountId,
          this.getApiHeader(true)
        )
        return response.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get files for the head account",
          true
        )
        if (shouldRetry)
          return await this.getAdviserFilesForClientAccount(accountId)
        return {
          folders: [],
          files: [],
          sections: [],
        } as SharedVaultDto
      }
    } else {
      throw new Error("Do not use static data, use MSW instead")
    }
  }

  public async getFoldersSharedByClient(
    userId: string,
    secondAttempt: boolean = false,
    includeDocumentRequestFiles: boolean = false
  ): Promise<SharedVaultDto> {
    if (this._connected) {
      try {
        const adviserApi = new AdviserApi({
          basePath: this.baseUrl,
        })
        const response = await adviserApi.apiAdviserClientsSharerIdGet(
          userId,
          includeDocumentRequestFiles,
          this.getApiHeader(true)
        )
        return response.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get folders for user",
          secondAttempt
        )
        if (shouldRetry)
          return await this.getFoldersSharedByClient(userId, true)
        return [] as SharedVaultDto
      }
    } else {
      throw new Error("Do not use static data, use MSW instead")
    }
  }

  public async getImageDataAsUrl(
    fileId: string,
    mimeType: string,
    secondAttempt: boolean = false,
    dummyPdfFileArrayBuffer?: string | ArrayBuffer | undefined
  ): Promise<any> {
    if (this._connected) {
      try {
        const nodeApi = new NodeApi({
          basePath: this.baseUrl,
        })

        var options = this.getApiHeader(true)
        options["responseType"] = "blob"

        const response = await nodeApi.apiNodeFileIdDownloadGet(
          fileId,
          false,
          options
        )
        if (response) {
          const blob = new Blob([response.data], { type: mimeType })
          return URL.createObjectURL(blob)
        }
        return ""
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get image data",
          secondAttempt
        )
        if (shouldRetry)
          return await this.getImageDataAsUrl(fileId, mimeType, true)
      }
    } else {
      //If the user has uploaded a file as part of a new project
      if (dummyPdfFileArrayBuffer) {
        const dummyResponse = getPdfPreviewResponse(dummyPdfFileArrayBuffer)
        const blob = new Blob([dummyResponse.data], { type: mimeType })
        return URL.createObjectURL(blob)
      } else {
        /* If the user is viewing an existing project, convert the stored base64 string
          to a blob so Abobe Embed PDF viewer can display its contents */

        //Get byte array (content) from PDF file
        const byteCharacters = window.atob(getPdfBase64.base64String)
        const byteArrays = Array.from(byteCharacters, (char) =>
          char.charCodeAt(0)
        )
        const byteArray = new Uint8Array(byteArrays)

        //Store byte array as a blob
        const pdfBlof = new Blob([byteArray], { type: "application/pdf" })

        //Get the blob URL to gift to the Adobe Embed PDF viewer
        return URL.createObjectURL(pdfBlof)
      }
    }
  }

  public async download(
    fileId: string,
    fileName: string,
    mimeType: string,
    secondAttempt: boolean = false
  ): Promise<any> {
    if (this._connected) {
      try {
        const nodeApi = new NodeApi({
          basePath: this.baseUrl,
        })
        var options = this.getApiHeader(true)
        options["responseType"] = "blob"
        const response = await nodeApi.apiNodeFileIdDownloadGet(
          fileId,
          true,
          options
        )
        if (response) {
          const blob = new Blob([response.data], { type: mimeType })
          triggerLinkDownload(blob, mimeType, fileName)
          return true
        }
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not download",
          secondAttempt
        )
        if (shouldRetry)
          return await this.download(fileId, fileName, mimeType, true)
        return false
      }
    } else {
      await sleep(500)
      const blob = new Blob(["downloaded text"], { type: "text/plain" })
      triggerLinkDownload(blob, "text/plain", fileName + ".txt")
      return true
    }
  }

  public async getRecentActivities(
    secondAttempt: boolean = false
  ): Promise<ActivityLogEntryDto[]> {
    if (this._connected) {
      try {
        const activityApi = new ActivityApi({
          basePath: this.baseUrl,
        })

        const response = await activityApi.apiActivityRecentGet(
          this.getApiHeader(true)
        )
        return response.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get recent activities",
          secondAttempt
        )
        if (shouldRetry) return await this.getRecentActivities(true)
        return []
      }
    } else {
      // TODO: change to throw new Error("Do not use static data, use MSW instead")
      console.error("Do not use static data, use MSW instead")
      return []
    }
  }

  //Update to vary the numbers
  public async getAllActivities(
    secondAttempt: boolean = false
  ): Promise<ActivityLogEntryDto[]> {
    if (this._connected) {
      try {
        const activityApi = new ActivityApi({
          basePath: this.baseUrl,
        })

        const response = await activityApi.apiActivityAllGet(
          0,
          100,
          this.getApiHeader(true)
        )
        return response.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get all activities",
          secondAttempt
        )
        if (shouldRetry) return await this.getAllActivities(true)
        return []
      }
    } else {
      throw new Error("Do not use static data, use MSW instead")
    }
  }

  public async getActivityLog(
    nodeId: string,
    secondAttempt: boolean = false
  ): Promise<ActivityLogEntryDto[]> {
    if (this._connected) {
      try {
        const activityApi = new ActivityApi({
          basePath: this.baseUrl,
        })

        const response = await activityApi.apiActivityNodeIdLogGet(
          nodeId,
          this.getApiHeader(true)
        )
        return response.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get activity log",
          secondAttempt
        )
        if (shouldRetry) return await this.getActivityLog(nodeId, true)
        return []
      }
    } else {
      // TODO: change to throw new Error("Do not use static data, use MSW instead")
      console.error("Do not use static data, use MSW instead")
      return []
    }
  }

  public async getPinnedFoldersAndFiles(
    secondAttempt: boolean = false
  ): Promise<any> {
    if (this._connected) {
      try {
        const nodeApi = new NodeApi({
          basePath: this.baseUrl,
        })

        const response = await nodeApi.apiNodePinnedGet(this.getApiHeader(true))
        return response.data as unknown as IFolder
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get pinned folders",
          secondAttempt
        )
        if (shouldRetry) return await this.getPinnedFoldersAndFiles(true)
      }
    } else {
      // TODO: change to throw new Error("Do not use static data, use MSW instead")
      console.error("Do not use static data, use MSW instead")
      return []
    }
  }

  public async getThumbnail(
    fileId: string,
    secondAttempt: boolean = false,
    isFullSizeThumbnail?: boolean
  ): Promise<string> {
    if (!this._connected) {
      var returnValue = Math.floor(Math.random() * 2 + 1)
      switch (returnValue) {
        case 0:
          return base64TestThumbnailLandscape
        default:
          return base64TestThumbnail
      }
    }
    try {
      const nodeApi = new NodeApi({
        basePath: this.baseUrl,
      })
      var options = this.getApiHeader(true)
      const response = await nodeApi.apiNodeFileGetThumbnailFileIdGet(
        fileId,
        isFullSizeThumbnail ? isFullSizeThumbnail : false,
        options
      )
      return response.data
    } catch (error: any) {
      let shouldRetry = await this.HandleError(
        error,
        "Could not get thumbnail",
        secondAttempt
      )
      if (shouldRetry)
        return await this.getThumbnail(
          fileId,
          true,
          isFullSizeThumbnail ? isFullSizeThumbnail : false
        )
      return ""
    }
  }

  public async getPreviewForFile(
    fileId: string,
    logPreview: boolean,
    secondAttempt: boolean = false
  ): Promise<string> {
    if (this._connected) {
      try {
        const nodeApi = new NodeApi({
          basePath: this.baseUrl,
        })
        var options = this.getApiHeader(true)
        const response = await nodeApi.apiNodeFileGetPreviewFileIdGet(
          fileId,
          logPreview,
          options
        )
        if (response) return response.data
        return ""
      } catch (error) {
        if (!secondAttempt) {
          let shouldRetry = await this.HandleError(
            error,
            "Could not get file preview"
          )
          if (shouldRetry)
            return await this.getPreviewForFile(fileId, logPreview, true)
        }
        return ""
      }
    } else {
      await sleep(1000)
      return getPdfBase64.base64String
    }
  }

  //NB: Ensure the Parent's folder level is input here rather than the new folder's
  public async createFolder(
    parentId: string | null,
    folderName: string,
    folderType: string,
    parentLevel: number,
    isEditable: boolean,
    isDeletable: boolean,
    canUpload: boolean,
    ownerId: string,
    isOwner: boolean,
    secondAttempt: boolean = false
  ): Promise<any> {
    if (this._connected) {
      try {
        const nodeApi = new NodeApi({
          basePath: this.baseUrl,
        })
        var body: CreateFolderDto = {
          parentId: parentId,
          name: folderName,
          type: folderType,
        }
        const response = await nodeApi.apiNodeFolderPost(
          body,
          this.getApiHeader(true)
        )
        return response.data
      } catch (error) {
        let shouldRetry = await this.HandleError(error, "", secondAttempt)
        if (shouldRetry) {
          return await this.createFolder(
            parentId,
            folderName,
            folderType,
            parentLevel,
            isEditable,
            isDeletable,
            canUpload,
            ownerId,
            isOwner,
            true
          )
        }
        return undefined
      }
    } else {
      sleep(1000)

      const suggestedFolders = getSuggestedFoldersFromLocalData() as IFolder[]
      const suggested = suggestedFolders.find(
        (e) => e.folderType === folderType
      )
      const newFolder: FolderTreeNodeDto = {
        id: uuidv4(),
        parentId: parentId,
        name: suggested?.name ?? folderName,
        guidanceText: suggested?.guidanceText ?? "",
        created: new Date().toISOString(),
        isOwner: isOwner,
        folderType: folderType,
        level: 1 + parentLevel,
        childFolders: [],
        isEditable: isEditable,
        isDeletable: isDeletable,
        canUpload: canUpload,
        ownerId: ownerId,
      }
      return newFolder
    }
  }

  /// Should notify and Should notify adviser are technically the same
  /// it wasnt working so BE changed it to pass in the header
  /// but actually we just needed to update the custom api for file upload
  /// will leave this here in case we need to revert and so it doesnt break anything
  /// Vitor Nunes
  public async uploadFile({
    file,
    shouldNotify = false,
    shouldHaveVersions = false,
    shouldHaveCommenting = false,
    shouldNotifyAdviser = false,
    secondAttempt = false,
  }: {
    file: IFile
    shouldNotify?: boolean
    shouldHaveVersions?: boolean
    shouldHaveCommenting?: boolean
    shouldNotifyAdviser?: boolean
    secondAttempt?: boolean
  }): Promise<any> {
    if (this._connected) {
      file.name = file.name.replace(/'/g, "`")
      try {
        const uploadApi = new UploadApi({
          basePath: this.baseUrl,
        })
        const blob = new Blob([file.binaryStr!], {
          type: file.mimeType ?? undefined,
        })

        const response = await uploadApi.apiUploadPost(
          file.parentId!,
          file.name!,
          blob,
          shouldHaveVersions,
          shouldHaveCommenting,
          shouldNotifyAdviser,
          this.getApiHeader(true, shouldNotify)
        )
        return response.data
      } catch (error) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not upload",
          secondAttempt
        )
        if (shouldRetry)
          return await this.uploadFile({
            file,
            shouldNotify,
            shouldHaveVersions,
            shouldHaveCommenting,
            shouldNotifyAdviser,
            secondAttempt: true,
          })
        const errorResponse = {
          id: file.id,
          name: file.name,
          extension: file.extension,
          fileType: "ERROR",
          fileName: (error as AxiosError).response?.data,
        }
        return [errorResponse]
      }
    } else {
      await sleep(500)
      const fileResponse: FileNodeDto = {
        ...file,
        id: uuidv4(),
        isOwner: true,
        dateShared: undefined,
        created: new Date().toISOString(),
        updated: undefined,
        isEditable: true,
        isDeletable: true,
        ownerId: undefined,
        createdById: undefined,
      }
      if (file.extension === "pdf") {
        fileResponse.hasThumbnail = true
        fileResponse.hasPreview = true
      }

      return [fileResponse]
    }
  }

  public async deleteFolder(
    folderId: string,
    secondAttempt: boolean = false
  ): Promise<boolean> {
    if (this._connected) {
      try {
        const nodeApi = new NodeApi({
          basePath: this.baseUrl,
        })

        await nodeApi.apiNodeFolderNodeIdDelete(
          folderId,
          this.getApiHeader(true)
        )
        return true
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not Delete folder",
          secondAttempt
        )
        if (shouldRetry) return await this.deleteFolder(folderId, true)
        return false
      }
    } else {
      await sleep(100)
      if (folderId) {
        return true
      } else {
        return false
      }
    }
  }

  public async deleteFile(
    fileId: string,
    secondAttempt: boolean = false
  ): Promise<boolean> {
    if (this._connected) {
      try {
        const nodeApi = new NodeApi({
          basePath: this.baseUrl,
        })

        await nodeApi.apiNodeFileNodeIdDelete(fileId, this.getApiHeader(true))
        return true
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not delete file",
          secondAttempt
        )
        if (shouldRetry) return await this.deleteFile(fileId)
        return false
      }
    } else {
      await sleep(100)
      if (fileId) {
        return true
      } else {
        return false
      }
    }
  }

  public async renameFile(
    fileId: string,
    fileName: string,
    secondAttempt: boolean = false
  ): Promise<boolean> {
    if (this._connected) {
      try {
        const nodeApi = new NodeApi({
          basePath: this.baseUrl,
        })
        await nodeApi.apiNodeFileFileIdRenamePut(
          fileId,
          fileName,
          this.getApiHeader(true)
        )
        return true
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not update file name",
          secondAttempt
        )
        if (shouldRetry) return await this.renameFile(fileId, fileName, true)
        return false
      }
    } else {
      return !!(fileId && fileName)
    }
  }

  public async renameFolder(
    folderId: string,
    newName: string,
    secondAttempt: boolean = false
  ): Promise<boolean> {
    if (this._connected) {
      try {
        const nodeApi = new NodeApi({
          basePath: this.baseUrl,
        })
        await nodeApi.apiNodeFolderFolderIdRenamePut(
          folderId,
          newName,
          this.getApiHeader(true)
        )
        return true
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not rename folder",
          secondAttempt
        )
        if (shouldRetry) return await this.renameFolder(folderId, newName, true)
        return false
      }
    }
    if (folderId && newName) {
      return true
    } else {
      return false
    }
  }

  public async pinFolder(
    folderId: string,
    pin: boolean,
    secondAttempt: boolean = false
  ): Promise<boolean> {
    if (this._connected) {
      try {
        const nodeApi = new NodeApi({
          basePath: this.baseUrl,
        })

        await nodeApi.apiNodeNodeIdPinPut(
          folderId,
          pin,
          this.getApiHeader(true)
        )
        return true
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not pin folder",
          secondAttempt
        )
        if (shouldRetry) return await this.pinFolder(folderId, pin, true)
        return false
      }
    } else {
      if (folderId) {
        return true
      } else {
        return false
      }
    }
  }

  public async pinFile(
    fileId: string,
    pin: boolean,
    secondAttempt: boolean = false
  ): Promise<boolean> {
    if (this._connected) {
      try {
        const nodeApi = new NodeApi({
          basePath: this.baseUrl,
        })

        await nodeApi.apiNodeNodeIdPinPut(fileId, pin, this.getApiHeader(true))
        return true
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not pin file",
          secondAttempt
        )
        if (shouldRetry) return await this.pinFile(fileId, pin, true)
        return false
      }
    } else {
      if (fileId) {
        return true
      } else {
        return false
      }
    }
  }

  public async getReviewResponse(
    fileId: string,
    secondAttempt: boolean = false
  ): Promise<ReviewResponse> {
    if (this._connected) {
      try {
        const nodeApi = new NodeApi({
          basePath: this.baseUrl,
        })

        const res = await nodeApi.apiNodeFileGetTagsFileIdGet(
          fileId,
          this.getApiHeader(true)
        )

        if (res.data === "null") {
          return { dates: [], tags: [], organizations: [] } as ReviewResponse
        }
        return JSON.parse(res.data) as ReviewResponse
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get file tags",
          secondAttempt
        )
        if (shouldRetry) return await this.getReviewResponse(fileId, true)
        return {} as ReviewResponse
      }
    } else {
      throw new Error("Do not use static data, use MSW instead")
    }
  }

  public async deleteTag(
    fileId: string,
    tagName: string,
    secondAttempt: boolean = false
  ): Promise<boolean> {
    if (this._connected) {
      try {
        const nodeApi = new NodeApi({
          basePath: this.baseUrl,
        })

        const res = await nodeApi.apiNodeFileDeleteTagFileIdPut(
          fileId,
          tagName,
          this.getApiHeader(true)
        )
        return res.status === 200
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not delete file tags",
          secondAttempt
        )
        if (shouldRetry) return await this.deleteTag(fileId, tagName, true)
        return false
      }
    } else {
      await sleep(100)
      return true
    }
  }

  public async deleteOrganisation(
    fileId: string,
    orgName: string,
    secondAttempt: boolean = false
  ): Promise<boolean> {
    if (this._connected) {
      try {
        const nodeApi = new NodeApi({
          basePath: this.baseUrl,
        })

        const res = await nodeApi.apiNodeFileDeleteOrganizationFileIdPut(
          fileId,
          orgName,
          this.getApiHeader(true)
        )
        return res.status === 200
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not delete organisation",
          secondAttempt
        )
        if (shouldRetry)
          return await this.deleteOrganisation(fileId, orgName, true)
        return false
      }
    } else {
      await sleep(100)
      return true
    }
  }

  public async getOrganisationClients(
    organisationId: string,
    start: number,
    count: number,
    filter: number,
    search: string,
    sortDesc: boolean,
    secondAttempt: boolean = false
  ): Promise<OrganisationsUsersDto> {
    if (this._connected) {
      try {
        const organisationApi = new OrganisationApi({ basePath: this.baseUrl })
        const response =
          await organisationApi.apiOrganisationOrganisationIdUsersGet(
            organisationId,
            start,
            count,
            filter,
            search,
            sortDesc,
            this.getApiHeader(true)
          )
        return response.data
      } catch (error) {
        const shouldRetry = await this.HandleError(
          error,
          "Could not fetch organisation clients",
          secondAttempt
        )

        if (shouldRetry) {
          return this.getOrganisationClients(
            organisationId,
            start,
            count,
            filter,
            search,
            sortDesc,
            true
          )
        } else {
          return [] as OrganisationsUsersDto
        }
      }
    } else {
      throw new Error("Do not use static data, use MSW instead")
    }
  }

  public async triggerReview(
    fileId: string,
    force: boolean,
    secondAttempt: boolean = false
  ): Promise<boolean> {
    if (this._connected) {
      try {
        const nodeApi = new NodeApi({
          basePath: this.baseUrl,
        })

        const res = await nodeApi.apiNodeFileTriggerFileReviewFileIdPost(
          fileId,
          force,
          this.getApiHeader(true)
        )
        return res.status === 200
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not trigger review",
          secondAttempt
        )
        if (shouldRetry) return await this.triggerReview(fileId, force, true)
        return false
      }
    } else {
      await sleep(100)
      return true
    }
  }

  public async getFileById(
    fileId: string,
    secondAttempt: boolean = false
  ): Promise<FileNodeDto> {
    if (this._connected) {
      try {
        const nodeApi = new NodeApi({
          basePath: this.baseUrl,
        })

        const res = await nodeApi.apiNodeFileFileIdTreenodeGet(
          fileId,
          this.getApiHeader(true)
        )

        return res.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get file from Id",
          secondAttempt
        )
        if (shouldRetry) return await this.getFileById(fileId)
        return {} as FileNodeDto
      }
    } else {
      await sleep(100)
      return {} as FileNodeDto
    }
  }

  public async getFlatFileById(
    fileId: string,
    secondAttempt: boolean = false
  ): Promise<FlatFileNodeDto> {
    if (this._connected) {
      try {
        const nodeApi = new NodeApi({
          basePath: this.baseUrl,
        })

        const res = await nodeApi.apiNodeFileFileIdFlatnodeGet(
          fileId,
          this.getApiHeader(true)
        )

        return res.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get flat file from Id",
          secondAttempt
        )
        if (shouldRetry) return await this.getFlatFileById(fileId)
        return {} as FlatFileNodeDto
      }
    } else {
      await sleep(100)
      return {} as FlatFileNodeDto
    }
  }

  public async getFolderById(
    folderId: string,
    secondAttempt: boolean = false
  ): Promise<FolderDto> {
    if (this._connected) {
      try {
        const nodeApi = new NodeApi({
          basePath: this.baseUrl,
        })

        const res = await nodeApi.apiNodeFolderFolderIdGet(
          folderId,
          this.getApiHeader(true)
        )

        return res.data as any as FolderDto
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get file from Id",
          secondAttempt
        )
        if (shouldRetry) return await this.getFolderById(folderId)
        return {} as FolderDto
      }
    } else {
      await sleep(100)
      return {} as FolderDto
    }
  }

  public async getFolderContents(
    folderId: string,
    secondAttempt: boolean = false
  ): Promise<FileNodeDto[]> {
    if (this._connected) {
      try {
        const nodeApi = new NodeApi({
          basePath: this.baseUrl,
        })

        const res = await nodeApi.apiNodeFolderFolderIdContentsGet(
          folderId,
          this.getApiHeader(true)
        )

        return res.data as any as FileNodeDto[]
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get folder contents",
          secondAttempt
        )
        if (shouldRetry) return await this.getFolderContents(folderId)
        return [] as FileNodeDto[]
      }
    } else {
      await sleep(100)
      return [] as FileNodeDto[]
    }
  }

  public async deleteDate(
    date: TDate,
    parentFileId: string,
    secondAttempt: boolean = false
  ): Promise<boolean> {
    if (this._connected) {
      try {
        const nodeApi = new NodeApi({
          basePath: this.baseUrl,
        })

        const res = await nodeApi.apiNodeFileDeleteDateFileIdPut(
          parentFileId,
          date.content,
          this.getApiHeader(true)
        )
        return res.status === 200
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not delete date",
          secondAttempt
        )
        if (shouldRetry) return await this.deleteDate(date, parentFileId)
        return false
      }
    } else {
      await sleep(100)
      return true
    }
  }

  public async getFileTags(
    secondAttempt: boolean = false
  ): Promise<FilesWithTagsDto[]> {
    if (this._connected) {
      try {
        const nodeApi = new NodeApi({
          basePath: this.baseUrl,
        })

        const res = await nodeApi.apiNodeGetAllTagsGet(this.getApiHeader(true))
        return res.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get tags",
          secondAttempt
        )
        if (shouldRetry) return await this.getFileTags(true)
        return [] as FilesWithTagsDto[]
      }
    } else {
      /*
      throw new Error("Do not use static data, use MSW insteadS")
      We'd prefer to throw an error here, but in this case it caused issues in a test (HomePage.SuggestedFolders.test.tsx)
      where mock data was different to what the test was expecting, and challenging to match.
      And clearing the mocks caused this error to be thrown and the test to fail.
      Given that its high priority to remove static data, this temporary solution is adequate for now.
      */
      console.error("Do not use static data, use MSW instead")
      return [] as FilesWithTagsDto[]
    }
  }

  public async getFlatFileList({
    secondAttempt = false,
    shouldRefreshCache = false,
    includeRequestFiles = false,
  }: {
    secondAttempt?: boolean
    shouldRefreshCache?: boolean
    includeRequestFiles?: boolean
  }): Promise<FlatFileNodeDto[]> {
    if (this._connected) {
      try {
        const nodeApi = new NodeApi({
          basePath: this.baseUrl,
        })
        const res = await nodeApi.apiNodeGetFlatFileListGet(
          false,
          shouldRefreshCache ? this._cachedCounter + 1 : this._cachedCounter,
          includeRequestFiles,
          this.getApiHeader(true)
        )
        return res.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get flat file list",
          secondAttempt
        )
        if (shouldRetry)
          return await this.getFlatFileList({
            secondAttempt: true,
            shouldRefreshCache: false,
          })
        return [] as FlatFileNodeDto[]
      }
    } else {
      // TODO: change to throw new Error("Do not use static data, use MSW instead")
      console.error("Do not use static data, use MSW instead")
      return []
    }
  }

  public async getImportantDocuments(
    targetUserId: string,
    doucmentCount: number,
    secondAttempt: boolean = false
  ): Promise<FileImportantRecentDto[]> {
    if (this._connected) {
      try {
        const nodeApi = new NodeApi({
          basePath: this.baseUrl,
        })
        const res = await nodeApi.apiNodeFileRecentimportantUserIdGet(
          targetUserId,
          doucmentCount,
          this.getApiHeader(true)
        )
        return res.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get flat file list",
          secondAttempt
        )
        if (shouldRetry) {
          return await this.getImportantDocuments(
            targetUserId,
            doucmentCount,
            true
          )
        }
        return [] as FlatFileNodeDto[]
      }
    } else {
      throw new Error("Do not use static data, use MSW instead")
    }
  }

  public async restoreDocument(
    fileId: string,
    secondAttempt: boolean = false
  ): Promise<boolean> {
    if (this._connected) {
      try {
        const nodeApi = new NodeApi({
          basePath: this.baseUrl,
        })
        await nodeApi.apiNodeFileRestoreNodeIdPost(
          fileId,
          this.getApiHeader(true)
        )
        return true
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not restore document",
          secondAttempt
        )
        if (shouldRetry) return await this.restoreDocument(fileId, true)
        return false
      }
    } else {
      await sleep(100)
      return true
    }
  }

  /**
   * Sharing API
   */
  public async getContacts(
    secondAttempt: boolean = false
  ): Promise<ContactDto[]> {
    if (this._connected) {
      try {
        const sharingApi = new SharingApi({
          basePath: this.baseUrl,
        })
        const response = await sharingApi.apiSharingContactsGet(
          this.getApiHeader(true)
        )
        return response.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get contacts",
          secondAttempt
        )
        if (shouldRetry) return await this.getContacts(true)
        return []
      }
    } else {
      /*
      throw new Error("Do not use static data, use MSW insteadS")
      We'd prefer to throw an error here, but in this case it caused issues in a number of test suites
      where throwing an error caused the test to fail, even though the test doesn't rely on the removed static data
      Given that its high priority to remove static data, this temporary solution is adequate for now.
      Related test:
      HomePage.test.tsx, HomePage.SuggestedFolders.test.tsx, HomePage.gaEvents.test.tsx, FolderPage.SuggestedFolders.test.tsx, UploadFileToFoldersWrapper.test.tsx
      */
      console.error("Do not use static data, use MSW instead")
      return [] as ContactDto[]
    }
  }

  public async getOrgContacts(
    secondAttempt: boolean = false
  ): Promise<OrgAdviserDto[]> {
    if (this._connected) {
      try {
        const sharingApi = new SharingApi({
          basePath: this.baseUrl,
        })
        const response = await sharingApi.apiSharingAdvisersGet(
          this.getApiHeader(true)
        )
        return response.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get org contacts",
          secondAttempt
        )
        if (shouldRetry) return await this.getOrgContacts(true)
        return []
      }
    } else {
      // TODO: change to throw new Error("Do not use static data, use MSW instead")
      console.error("Do not use static data, use MSW instead")
      return [] as OrgAdviserDto[]
    }
  }

  public async getUsersSharingWithCurrentUser(
    secondAttempt: boolean = false
  ): Promise<UserDto[]> {
    if (this._connected) {
      try {
        const sharingApi = new SharingApi({
          basePath: this.baseUrl,
        })
        const response = await sharingApi.apiSharingUsersListGet(
          this.getApiHeader(true)
        )
        return response.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get sharing options",
          secondAttempt
        )
        if (shouldRetry) return await this.getUsersSharingWithCurrentUser(true)
        return []
      }
    } else {
      // TODO: change to throw new Error("Do not use static data, use MSW instead")
      console.error("Do not use static data, use MSW instead")
      return []
    }
  }

  public async removeContact(
    userId: string,
    secondAttempt: boolean = false
  ): Promise<boolean> {
    if (this._connected) {
      try {
        const sharingApi = new SharingApi({
          basePath: this.baseUrl,
        })
        await sharingApi.apiSharingRemoveContactContactIdDelete(
          userId,
          this.getApiHeader(true)
        )
        return true
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not remove contact",
          secondAttempt
        )
        if (shouldRetry) return await this.removeContact(userId, true)
        return false
      }
    } else {
      await sleep(1000)
      return true
    }
  }

  public async addContact(
    contact: IContactInvite,
    selectedSharingWithMeUserId?: string,
    secondAttempt: boolean = false
  ): Promise<any> {
    if (this._connected) {
      try {
        const sharingApi = new SharingApi({
          basePath: this.baseUrl,
        })

        //Format phone number so its accepted by the API
        if (contact.phoneNumber) {
          contact.phoneNumber =
            contact.countryCode +
            contact.phoneNumber.replace(/[\s\-()]/g, "").replace(/^0+/g, "")
        }
        //otherwise dont update the country code if there is no phone number
        else {
          contact.countryCode = ""
        }

        const response = await sharingApi.apiSharingAddContactPost(
          contact,
          this.getApiHeader(true)
        )
        return response.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not add contact",
          secondAttempt
        )
        if (shouldRetry) {
          return await this.addContact(
            contact,
            selectedSharingWithMeUserId,
            true
          )
        }
      }
    } else {
      await sleep(500)
      const contactResponse: ContactDto = {
        contactId:
          selectedSharingWithMeUserId && selectedSharingWithMeUserId !== ""
            ? selectedSharingWithMeUserId
            : uuidv4(),
        firstName: contact.firstName,
        surname: contact.surname,
        name: `${contact.firstName} ${contact.surname}`,
        email: contact.email,
        relationship: contact.relationship,
        isPending: false,
        contactType: "User",
        reference: null,
        phoneNumber: null,
        created: new Date(),
        nodeShares: [],
      }
      return contactResponse
    }
  }

  public async getSharingClientsList(
    secondAttempt: boolean = false
  ): Promise<UserDto[]> {
    if (this._connected) {
      try {
        const adviserApi = new AdviserApi({
          basePath: this.baseUrl,
        })
        const response = await adviserApi.apiAdviserClientsListGet(
          this.getApiHeader(true)
        )
        return response.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get client list",
          secondAttempt
        )
        if (shouldRetry) return await this.getSharingClientsList(true)
        return []
      }
    } else {
      // TODO: change to throw new Error("Do not use static data, use MSW instead")
      console.error("Do not use static data, use MSW instead")
      return []
    }
  }

  public async addClient(
    client: ClientInviteDto,
    secondAttempt: boolean = false
  ): Promise<UserDto> {
    if (this._connected) {
      try {
        const adviserApi = new AdviserApi({
          basePath: this.baseUrl,
        })
        const body: ClientInviteDto = client
        const response = await adviserApi.apiAdviserClientInvitePost(
          body,
          this.getApiHeader(true)
        )
        return response.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not add client",
          secondAttempt
        )
        if (shouldRetry) return await this.addClient(client, true)
      }
    } else {
      await sleep(500)
      const newClient: UserDto = {
        userId: "123",
        created: new Date(),
        email: client.email ?? "",
        firstName: client.firstName,
        surname: client.surname,
        phoneNumber: "",
        countryCode: "",
        emailConfirmed: false,
        organisationId: "",
        phoneNumberConfirmed: false,
        roles: [],
        userName: client.email,
      }
      return newClient
    }
    return {} as UserDto
  }

  public async removeClient(
    clientId: string,
    secondAttempt: boolean = false
  ): Promise<boolean> {
    if (this._connected) {
      try {
        const adviserApi = new AdviserApi({
          basePath: this.baseUrl,
        })
        await adviserApi.apiAdviserClientClientIdDelete(
          clientId,
          this.getApiHeader(true)
        )
        return true
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not remove client",
          secondAttempt
        )
        if (shouldRetry) return await this.removeClient(clientId, true)
      }
    } else {
      await sleep(500)
      return true
    }
    return false
  }

  public async resendContactInvite(
    contact: any,
    secondAttempt: boolean = false
  ): Promise<boolean> {
    if (this._connected) {
      try {
        const sharingApi = new SharingApi({
          basePath: this.baseUrl,
        })
        await sharingApi.apiSharingResendInvitePost(
          {
            firstName: contact.firstName,
            surname: contact.surname,
            email: contact.email,
            relationship: contact.relationship,
            message: "",
            sendCopy: false,
          } as ContactInviteDto,
          this.getApiHeader(true)
        )
        return true
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Failed to resend contact invite",
          secondAttempt
        )
        if (shouldRetry) return await this.resendContactInvite(contact, true)
        return false
      }
    } else {
      await sleep(1000)
      return true
    }
  }

  public async getContactsForNode(
    nodeId: string,
    secondAttempt: boolean = false
  ): Promise<NodeShareDto[]> {
    if (this._connected) {
      try {
        const sharingApi = new SharingApi({
          basePath: this.baseUrl,
        })
        const response = await sharingApi.apiSharingNodeIdListGet(
          nodeId,
          this.getApiHeader(true)
        )
        return response.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get contacts for node",
          secondAttempt
        )
        if (shouldRetry) return await this.getContactsForNode(nodeId, true)
        return []
      }
    } else {
      throw new Error("Do not use static data, use MSW instead")
    }
  }

  public async getSharesForNode(
    nodeId: string,
    secondAttempt: boolean = false
  ): Promise<NodeShareDto[]> {
    if (this._connected) {
      try {
        const sharingApi = new SharingApi({
          basePath: this.baseUrl,
        })
        const response = await sharingApi.apiSharingNodeIdListGet(
          nodeId,
          this.getApiHeader(true)
        )
        return response.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get nodesShares for nodeId:" + nodeId,
          secondAttempt
        )
        if (shouldRetry) return await this.getSharesForNode(nodeId, true)
      }
    } else {
      await sleep(1000)
    }
    return []
  }

  public async postNodeSharesForNode(
    nodeId: string,
    nodeShares: NodeShareDto[],
    secondAttempt: boolean = false
  ): Promise<any> {
    if (this._connected) {
      try {
        const sharingApi = new SharingApi({
          basePath: this.baseUrl,
        })
        const response = await sharingApi.apiSharingNodeNodeIdPost(
          nodeId,
          nodeShares,
          this.getApiHeader(true)
        )
        if (response) {
          return true
        }
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not save sharing permissions for node: " + nodeId,
          secondAttempt
        )
        if (shouldRetry) {
          return await this.postNodeSharesForNode(nodeId, nodeShares, true)
        }
        return false
      }
    } else {
      await sleep(1000)
      if (nodeId !== "") {
        return true
      }
      return false
    }
  }

  public async getNodesSharedWithContact(
    contactId: string,
    secondAttempt: boolean = false
  ): Promise<NodeShareDto[]> {
    if (this._connected) {
      try {
        const sharingApi = new SharingApi({
          basePath: this.baseUrl,
        })
        const response = await sharingApi.apiSharingContactContactIdGet(
          contactId,
          this.getApiHeader(true)
        )
        return response.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get nodes shared with userId:" + contactId,
          secondAttempt
        )
        if (shouldRetry) {
          return await this.getNodesSharedWithContact(contactId, true)
        }
        return []
      }
    } else {
      // TODO: change to throw new Error("Do not use static data, use MSW instead")
      console.error("Do not use static data, use MSW instead")
      return []
    }
  }

  public async postNodeSharesForContact(
    contactId: string,
    nodeShares: any[],
    secondAttempt: boolean = false
  ): Promise<string> {
    if (this._connected) {
      try {
        const sharingApi = new SharingApi({
          basePath: this.baseUrl,
        })
        const response = await sharingApi.apiSharingContactContactIdPost(
          contactId,
          nodeShares,
          this.getApiHeader(true)
        )
        return response.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not share nodes with userId: " + contactId,
          secondAttempt
        )
        if (shouldRetry) {
          return await this.postNodeSharesForContact(
            contactId,
            nodeShares,
            true
          )
        }
      }
    } else {
      if (contactId !== "") {
        return "Permissions are saved"
      }
    }
    return ""
  }

  /**
   * User API
   */
  public async getUser(
    userId?: string,
    email?: string,
    secondAttempt: boolean = false
  ): Promise<UserDto> {
    if (this._connected) {
      try {
        const userApi = new UserApi({
          basePath: this.baseUrl,
        })

        let response
        if (userId) {
          response = await userApi.apiUserGetGet(
            undefined,
            userId,
            this.getApiHeader(true)
          )
        } else if (email) {
          response = await userApi.apiUserGetGet(
            email,
            undefined,
            this.getApiHeader(true)
          )
        } else {
          response = await userApi.apiUserGetGet(
            undefined,
            undefined,
            this.getApiHeader(true)
          )
        }
        this.apiConnection.apiUsername = response.data.userName ?? undefined
        return response.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get user",
          secondAttempt
        )
        if (shouldRetry) return await this.getUser(userId, email, true)
        return {}
      }
    } else {
      throw new Error("Do not use static data, use MSW instead")
    }
  }

  public async cancelUserSubscription(
    secondAttempt: boolean = false
  ): Promise<string> {
    if (this._connected) {
      try {
        const userApi = new UserApi({
          basePath: this.baseUrl,
        })

        const response = await userApi.apiUserRequestCancellationPost(
          this.getApiHeader(true)
        )

        return response.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get user",
          secondAttempt
        )
        if (shouldRetry) return await this.cancelUserSubscription(true)
        return ""
      }
    } else {
      return ""
    }
  }

  public async putAcceptTerms(
    secondAttempt: boolean = false
  ): Promise<boolean> {
    if (this._connected) {
      try {
        const userApi = new UserApi({
          basePath: this.baseUrl,
        })

        await userApi.apiUserAcceptTermsPut(this.getApiHeader(true))

        return true
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not accept TC's",
          secondAttempt
        )
        if (shouldRetry) return await this.putAcceptTerms(true)
        return false
      }
    } else {
      return true
    }
  }

  public async sendVerificationEmail(
    email: string,
    secondAttempt: boolean = false
  ): Promise<string> {
    if (this._connected) {
      try {
        const userApi = new UserApi({
          basePath: this.baseUrl,
        })
        const res = await userApi.apiUserVerificationCodePost(
          email,
          "",
          this.getApiHeader(true)
        )
        return res.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not post email code",
          secondAttempt
        )
        if (shouldRetry) return await this.sendVerificationEmail(email, true)
      }
    }
    return ""
  }

  public async sendVerificationText(
    phoneNumber: string,
    secondAttempt: boolean = false
  ): Promise<string> {
    if (this._connected) {
      try {
        const userApi = new UserApi({
          basePath: this.baseUrl,
        })
        const res = await userApi.apiUserVerificationCodePost(
          "",
          phoneNumber,
          this.getApiHeader(true)
        )
        return res.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not post email code",
          secondAttempt
        )
        if (shouldRetry) {
          return await this.sendVerificationText(phoneNumber, true)
        }
        return "error"
      }
    }
    return ""
  }

  public async postConfirmCode(
    secondAttempt: boolean = false,
    code: string
  ): Promise<boolean> {
    if (this._connected) {
      try {
        const userApi = new UserApi({
          basePath: this.baseUrl,
        })
        const res = await userApi.apiUserConfirmCodePost(
          code,
          this.getApiHeader(true)
        )
        return res.status === 200
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not confirm code",
          secondAttempt
        )
        if (shouldRetry) {
          return await this.postConfirmCode(true, code)
        } else {
          return false
        }
      }
    }
    return true
  }

  public async putUpdateUser(
    secondAttempt: boolean = false,
    userID: string,
    user: UserDto
  ): Promise<any> {
    if (this._connected) {
      try {
        const userApi = new UserApi({
          basePath: this.baseUrl,
        })
        const res = await userApi.apiUserUserIdPut(
          userID,
          user,
          this.getApiHeader(true)
        )
        if (res.status === 200) {
          return res as UserDto
        }
        return false
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not update user",
          secondAttempt
        )
        if (shouldRetry) {
          return await this.putUpdateUser(true, userID, user)
        }
      }
    }
    return user as UserDto
  }

  /*
  Connectivity API
  */

  public async postSuggestedProviders(
    provider: SuggestedProviderDto,
    secondAttempt: boolean = false
  ): Promise<string> {
    if (this._connected) {
      try {
        const connectivityApi = new ConnectivityApi({
          basePath: this.baseUrl,
        })
        const res = await connectivityApi.apiConnectivitySuggestedProviderPost(
          provider,
          this.getApiHeader(true)
        )
        return res.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could add suggested provider",
          secondAttempt
        )
        if (shouldRetry)
          return await this.postSuggestedProviders(provider, true)
      }
    }
    return ""
  }

  public async getProviderList(
    secondAttempt: boolean = false
  ): Promise<Provider[]> {
    if (this._connected) {
      try {
        const connectivityApi = new ConnectivityApi({
          basePath: this.baseUrl,
        })
        const res = await connectivityApi.apiConnectivityProvidersGet(
          true,
          this.getApiHeader(true)
        )
        return res.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get providers",
          secondAttempt
        )
        if (shouldRetry) return await this.getProviderList(true)
        return []
      }
    } else {
      // TODO: change to throw new Error("Do not use static data, use MSW instead")
      console.error("Do not use static data, use MSW instead")
      return []
    }
  }

  public async getIndustryList(
    secondAttempt: boolean = false
  ): Promise<Industry[]> {
    if (this._connected) {
      try {
        const connectivityApi = new ConnectivityApi({
          basePath: this.baseUrl,
        })

        const res = await connectivityApi.apiConnectivityIndustriesGet(
          true,
          this.getApiHeader(true)
        )
        return res.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get industries",
          secondAttempt
        )
        if (shouldRetry) return await this.getIndustryList(true)
        return []
      }
    } else {
      return getIndustriesFromLocalData()
    }
  }

  //Parent Id is just for local usage
  public async postProviderAccount(
    providerAccount: ProviderAccountDto,
    parentId?: string,
    folderId?: string,
    secondAttempt: boolean = false
  ): Promise<any> {
    let shouldRetry = false
    if (this._connected) {
      try {
        const connectivityApi = new ConnectivityApi({
          basePath: this.baseUrl,
        })
        const res = await connectivityApi.apiConnectivityProviderAccountPost(
          providerAccount,
          folderId,
          this.getApiHeader(true)
        )
        return res.data
      } catch (error: any) {
        shouldRetry = await this.HandleError(
          error,
          "Could not add provider account",
          secondAttempt
        )
        if (shouldRetry) {
          return await this.postProviderAccount(
            providerAccount,
            parentId,
            folderId,
            true
          )
        }
        return "Connection error"
      }
    }
    if (!this._connected && !shouldRetry) {
      return {
        name: getProviderFolderName(providerAccount),
        folderType: "ConnectedAccountFolder",
        folderId: "1234",
        parentId: parentId,
        externalCode: providerAccount.providerCode,
      } as FolderDto
    }
    return true
  }

  public async getProviderAccountStatuses(
    secondAttempt: boolean = false
  ): Promise<any> {
    if (this._connected) {
      try {
        const connectivityApi = new ConnectivityApi({
          basePath: this.baseUrl,
        })
        const res =
          await connectivityApi.apiConnectivityGetFolderConnectionStatusGet(
            this.getApiHeader(true)
          )
        return res.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get statuses",
          secondAttempt
        )
        if (shouldRetry) return await this.getProviderAccountStatuses(true)
      }
    }
    //Replace with call with to react-app-config once ready
    return GetLocalFolderConnectionStatus()
  }

  public async disconnectConnectedFolder(
    folderId: string,
    secondAttempt: boolean = false
  ): Promise<boolean> {
    if (this._connected) {
      try {
        const connectivityApi = new ConnectivityApi({
          basePath: this.baseUrl,
        })
        const res =
          await connectivityApi.apiConnectivityProviderAccountDisconnectPut(
            folderId,
            this.getApiHeader(true)
          )
        return res.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not delete connection",
          secondAttempt
        )
        if (shouldRetry)
          return await this.disconnectConnectedFolder(folderId, true)
        return false
      }
    }
    return true
  }

  public async reconnectConnectedFolder(
    folderId: string,
    secondAttempt: boolean = false
  ): Promise<boolean> {
    if (this._connected) {
      try {
        const connectivityApi = new ConnectivityApi({
          basePath: this.baseUrl,
        })
        const res =
          await connectivityApi.apiConnectivityProviderAccountReconnectPut(
            folderId,
            this.getApiHeader(true)
          )
        return res.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not reconnect folder",
          secondAttempt
        )
        if (shouldRetry)
          return await this.reconnectConnectedFolder(folderId, true)
        return false
      }
    }
    return true
  }

  public async deleteConnectedFolder(
    folderId: string,
    secondAttempt: boolean = false
  ): Promise<boolean> {
    if (this._connected) {
      try {
        const connectivityApi = new ConnectivityApi({
          basePath: this.baseUrl,
        })
        const res = await connectivityApi.apiConnectivityProviderAccountDelete(
          folderId,
          this.getApiHeader(true)
        )
        return res.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not Delete folder",
          secondAttempt
        )
        if (shouldRetry) return await this.deleteConnectedFolder(folderId, true)
        return false
      }
    }
    return true
  }

  public async getProviderAccountInfo(
    folder: IFolder,
    secondAttempt: boolean = false
  ): Promise<AccountDetailDto> {
    if (this._connected) {
      try {
        const connectivityApi = new ConnectivityApi({
          basePath: this.baseUrl,
        })
        const res =
          await connectivityApi.apiConnectivityProviderAccountAccountInfoGet(
            folder.id,
            this.getApiHeader(true)
          )
        return res.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get account provider info",
          secondAttempt
        )
        if (shouldRetry) return await this.getProviderAccountInfo(folder, true)
        return {}
      }
    } else {
      return getLocalProviderAccountInfo(folder)
    }
  }

  public async setFolderStatusesAsRead(
    folderIds: string[],
    secondAttempt: boolean = false
  ): Promise<boolean> {
    if (this._connected) {
      try {
        const connectivityApi = new ConnectivityApi({
          basePath: this.baseUrl,
        })
        await connectivityApi.apiConnectivityReadStatusPut(
          folderIds,
          this.getApiHeader(true)
        )
        return true
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not set folder statuses as read",
          secondAttempt
        )
        if (shouldRetry)
          return await this.setFolderStatusesAsRead(folderIds, true)
        return false
      }
    }
    return true
  }

  /*
   *  Notification Controller
   */

  public async getNotifications(
    secondAttempt: boolean = false
  ): Promise<NotificationDto[]> {
    if (this._connected) {
      try {
        const notificationApi = new NotificationApi({
          basePath: this.baseUrl,
        })

        const response = await notificationApi.apiNotificationGet(
          this.getApiHeader(true)
        )

        return response.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get notifications",
          secondAttempt
        )
        if (shouldRetry) return await this.getNotifications(true)
        return []
      }
    } else {
      //Grab from static data once backend is setup
      return notificationsData as unknown as NotificationDto[]
    }
  }

  public async setNotificationsToRead(
    ids: string[],
    secondAttempt: boolean = false
  ): Promise<any> {
    if (this._connected) {
      try {
        const notificationApi = new NotificationApi({
          basePath: this.baseUrl,
        })

        const response =
          await notificationApi.apiNotificationSetNotificationsAsReadPut(
            ids,
            this.getApiHeader(true)
          )

        return response.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not set notifications",
          secondAttempt
        )
        if (shouldRetry) return await this.setNotificationsToRead(ids, true)
        return []
      }
    }
  }

  /*
   * Reminder Controller
   */

  public async getReminders(
    secondAttempt: boolean = false
  ): Promise<ReminderDto[]> {
    if (this._connected) {
      try {
        const reminderApi = new ReminderApi({
          basePath: this.baseUrl,
        })

        const response = await reminderApi.apiReminderGet(
          LEGADO_REMINDER_APPLICATION_ID,
          this.getApiHeader(true)
        )

        return response.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not get reminders",
          secondAttempt
        )
        if (shouldRetry) return await this.getReminders(true)
        return []
      }
    }
    return [] //Could get some static reminders here
  }

  public async createReminder(
    reminderDto: CreateReminderDto,
    secondAttempt: boolean = false
  ): Promise<string> {
    if (this._connected) {
      reminderDto.applicationId = LEGADO_REMINDER_APPLICATION_ID
      try {
        const reminderApi = new ReminderApi({
          basePath: this.baseUrl,
        })

        const response = await reminderApi.apiReminderPost(
          reminderDto,
          this.getApiHeader(true)
        )
        //Returns reminder ID
        return response.data
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not create reminder",
          secondAttempt
        )
        if (shouldRetry) return await this.createReminder(reminderDto, true)
        return ""
      }
    }
    return "fake-id"
  }

  public async updateReminder(
    reminderDto: UpdateReminderDto,
    secondAttempt: boolean = false
  ): Promise<boolean> {
    if (this._connected) {
      reminderDto.applicationId = LEGADO_REMINDER_APPLICATION_ID
      try {
        const reminderApi = new ReminderApi({
          basePath: this.baseUrl,
        })

        const response = await reminderApi.apiReminderPut(
          reminderDto,
          this.getApiHeader(true)
        )

        return response.status === 200
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not update reminder",
          secondAttempt
        )
        if (shouldRetry) return await this.updateReminder(reminderDto, true)
        return false
      }
    }
    return true
  }

  public async deleteReminder(
    reminderId: string,
    secondAttempt: boolean = false
  ): Promise<boolean> {
    if (this._connected) {
      try {
        const reminderApi = new ReminderApi({
          basePath: this.baseUrl,
        })

        const response = await reminderApi.apiReminderDelete(
          reminderId,
          this.getApiHeader(true)
        )

        return response.status === 200
      } catch (error: any) {
        let shouldRetry = await this.HandleError(
          error,
          "Could not delete reminder",
          secondAttempt
        )
        if (shouldRetry) return await this.deleteReminder(reminderId, true)
        return false
      }
    }
    return true
  }

  /**
   * PubSub
   */

  public async setupUserWebhook(
    dispatch: (value: any) => void,
    thumbnailDispatch: (value: IThumbnailAction) => void,
    toastDispatch: Dispatch<IToastAction>,
    theme: string,
    secondAttempt: boolean = false
  ) {
    if (this._connected) {
      try {
        const pubSubApi = new PubSubApi({
          basePath: this.baseUrl,
        })

        const response = await pubSubApi.apiPubSubGetWebSocketConnectionGet(
          this.getApiHeader(true)
        )

        if (response?.data?.url) {
          await createWebHook({
            websocketUrl: response.data.url,
            websocketName: "UserWebhook",
            onmessage: async ({ data, ws }: { data: any; ws: WebSocket }) => {
              ParseWebhookMessage(
                data,
                dispatch,
                thumbnailDispatch,
                toastDispatch,
                theme,
                "currentUser"
              )
            },
          })
        }
      } catch (error) {
        let shouldRetry = await this.HandleError(error, "", secondAttempt)
        if (shouldRetry)
          await this.setupUserWebhook(
            dispatch,
            thumbnailDispatch,
            toastDispatch,
            theme,
            true
          )
      }
    }
  }

  public async joinAnotherUserWebHook(
    userId: string,
    dispatch: (value: any) => void,
    thumbnailDispatch: (value: IThumbnailAction) => void,
    toastDispatch: Dispatch<IToastAction>,
    theme: string,
    relationship: "client" | "contact",
    currentUser?: IUser,
    secondAttempt: boolean = false
  ) {
    if (this.connected) {
      try {
        const pubSubApi = new PubSubApi({
          basePath: this.getBaseUrl,
        })

        const response =
          await pubSubApi.apiPubSubGetWebSocketConnectionUserIdGet(
            userId,
            this.getApiHeader(true)
          )

        if (response?.data?.url) {
          this._thirdPartywebSocket = await createWebHook({
            websocketUrl: response.data.url,
            websocketName: userId,
            onmessage: async ({ data, ws }: { data: any; ws: WebSocket }) => {
              ParseWebhookMessage(
                data,
                dispatch,
                thumbnailDispatch,
                toastDispatch,
                theme,
                relationship,
                userId,
                currentUser
              )
            },
          })
        }
      } catch (error) {
        let shouldRetry = await this.HandleError(error, "", secondAttempt)
        if (shouldRetry)
          await this.setupUserWebhook(
            dispatch,
            thumbnailDispatch,
            toastDispatch,
            theme,
            true
          )
      }
    }
  }

  public async closeThirdPartyWSConnection() {
    try {
      if (!this._thirdPartywebSocket) return
      console.log("closing third party websocket")
      this._thirdPartywebSocket.close()
    } catch {
      console.log("error closing third party websocket")
    }
  }

  public async doTokenRefresh(loginType?: string) {
    if (!this._shouldAutoRefreshToken && loginType === loginTypes.PASSWORD) {
      console.log("Auto refresh token is disabled")
      return false
    }

    const savedRefreshTokenCode = localStorage.getItem(
      LocalStorageKeys.RefreshToken
    )

    if (
      loginType === loginTypes.SSO &&
      (this.apiConnection.refreshTokenCode === null ||
        savedRefreshTokenCode === null)
    ) {
      return false
    }
    console.log("Refreshing token")

    try {
      const authApi = new AuthApi({ basePath: this.baseUrl })
      const response = await authApi.apiAuthRefreshPost(
        this.apiConnection.refreshTokenCode ?? savedRefreshTokenCode ?? "",
        this.apiConnection.isPrimaryUser,
        this.getApiHeader(true)
      )
      this.token = response.data
      localStorage.setItem(LocalStorageKeys.Token, response.data)

      console.log("Token refreshed")
      return true
    } catch (error) {
      console.error(error)
      this.token = undefined
      this.apiConnection.refreshTokenCode = null
      localStorage.removeItem(LocalStorageKeys.RefreshToken)
      this.handleTimeout(true)
      console.log("Token Not Refreshed")
      return false
    }
  }

  // Get the external token for platforms with SSO
  // isPrimaryUser is a flag which tells us wether its the customer facing app or the admin app, which affects how to get the token from FNZ
  // (it would now be possible to determine this in the back end using the origin header, so that bit can be removed once back end changes have been made)
  public async getExternalToken(code: string, isPrimaryUser: boolean) {
    try {
      const authApi = new AuthApi({ basePath: this.baseUrl })
      const response = await authApi.apiAuthTokenPost(
        code,
        isPrimaryUser,
        this.getApiHeader(true)
      )
      return response.data
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  protected getApiHeader(withToken: boolean, shouldNotify: boolean = false) {
    type OptionValues = { [key: string]: any }
    type HeaderValues = { [key: string]: string }
    const headerValues: HeaderValues = {}

    if (this.apiConnection && this.apiConnection.apiKey) {
      headerValues["ApiKey"] = this.apiConnection.apiKey
    }

    if (withToken) {
      headerValues["Authorization"] = "Bearer " + this.token
    }

    var optionValues: OptionValues = {}
    optionValues["headers"] = headerValues

    if (shouldNotify) {
      optionValues["query"] = { notify: true }
    }

    return optionValues
  }

  protected HandleError = async (
    error: any,
    errorMessage: string,
    secondAttempt: boolean = false
  ) => {
    const api = ApiController.getInstance()
    try {
      if (error.response.status === 401 && !secondAttempt) {
        return await api.doTokenRefresh()
      } else if (error.response.status === 401 && secondAttempt) {
        api.handleTimeout(true)
        return false
      }
    } catch (error) {
      console.error(error)
    }
    console.log(errorMessage)
    console.error(error)
    return false
  }

  public async getNotificationContent(
    notification: PubSubNotificationDto,
    secondAttempt: boolean = false
  ): Promise<any> {
    if (this._connected) {
      try {
        const notificationApi = new NotificationApi({
          basePath: this.baseUrl,
        })

        const response =
          await notificationApi.apiNotificationGetPopupNotificationContentPost(
            notification,
            this.getApiHeader(true)
          )

        return response.data
      } catch (error) {
        let shouldRetry = await this.HandleError(error, "", secondAttempt)
        if (shouldRetry)
          return await this.getNotificationContent(notification, true)
        this.handleTimeout(true)
      }
    }
  }
}
