/* istanbul ignore file */
export const notificationMessages = {
  addContact: {
    SUCCESS: "", //Do not show a message on success
    ERROR: "An error has occurred while trying to add the Contact",
  },
  removeContact: {
    SUCCESS: "The Contact has been removed",
    ERROR: "An error has occurred while trying to remove the Contact",
  },
  reinviteContact: {
    SUCCESS:
      "Another invitation has been sent, please ask your contact to check their inbox.",
    ERROR: "An error has just occured while trying to reinvite contact.",
  },
  downloadFile: {
    SUCCESS: "File has been successfully downloaded",
    ERROR: "An error has occurred while trying to download file",
  },
  uploadFile: {
    SUCCESS: "File has been successfully uploaded",
    ERROR: "An error has occured while trying to upload file",
  },
  uploadFiles: {
    SUCCESS: "Upload complete",
    ERROR: "Upload failed",
  },
  uploadInvalidFileFormat: {
    ERROR: "Invalid file format",
  },
  postNodeSharesForNode: {
    SUCCESS: "Sharing Permissions have been saved",
    ERROR: "An error has occurred while trying to save sharing permissions",
  },
  createSuggestedSection: {
    SUCCESS: "Add one of the suggested folders or create your own",
    ERROR: "An error has occurred while trying to create new section",
  },
  createSuggestedFolder: {
    SUCCESS: "Would you like to enter this folder or create more?",
    ERROR: "An error has occurred while trying to create new folder",
  },
  createSubfolder: {
    SUCCESS: "New folder has been created",
    ERROR: "An error has occurred while trying to create new folder",
  },
  pinFolder: {
    SUCCESS: "Folder has been successfully pinned to your home page",
    ERROR: "An error has occurred while trying to pin folder",
  },
  unpinFolder: {
    SUCCESS: "Folder has been successfully unpinned from your home page",
    ERROR: "An error has occurred while trying to unpin folder",
  },
  pinFile: {
    SUCCESS: "File has been successfully pinned to your home page",
    ERROR: "An error has occurred while trying to pin file",
  },
  unpinFile: {
    SUCCESS: "File has been successfully unpinned from your home page",
    ERROR: "An error has occurred while trying to unpin file",
  },
  renameFolder: {
    SUCCESS: "Folder has been successfully renamed",
    ERROR: "An error has occurred while trying to rename folder",
  },
  renameFile: {
    SUCCESS: "File has been successfully renamed",
    ERROR: "An error has occurred while trying to rename file",
  },
  getFolders: {
    SUCCESS: "No message required on success",
    ERROR: "There is no vault for this user",
  },
  setApiCredentials: {
    SUCCESS: "No message required on success",
    ERROR: "Connection Attempt Failed",
  },
  navigateToFileOrFolder: {
    SUCCESS: "", //Do not show a message on success
    ERROR: "This file / folder no longer exists in document vault",
  },
  editImportedFolderPermissions: {
    WARNING: "You are unable to edit imported folders",
  },
  invalidRoute: {
    ERROR: "This page no longer exists. Returning you to document home.",
  },
  invalidUser: {
    ERROR: "User not found",
  },
  tagAdd: {
    SUCCESS: "Tag added",
    ERROR: "Tag could not be added",
  },
  userHasNoModules: {
    SUCCESS: "", //Do not show a message on success
    ERROR: "User has no Modules",
  },
  addSuggestedProvider: {
    SUCCESS:
      "Provider Request has been sent. Add another provider in the meantime.", //Do not show a message on success
    ERROR: "Something went wrong. Please try again.",
  },
  updatePhoneNumber: {
    SUCCESS: "Mobile phone number has been updated.",
    ERROR: "An error has occurred while trying to update mobile phone number",
  },
  removeConnectedFolder: {
    SUCCESS:
      "Please disconnect all providers in this section to be able to remove.",
  },
  verificationCode: {
    ERROR: "Incorrect verification code entered",
  },
  accountInfo: {
    ERROR: "An error has occurred while trying to get account information",
  },
  copiedToClipboard: {
    SUCCESS: "Copied to clipboard",
  },
  resentVerificationCode: {
    SUCCESS: "Verification code has been resent",
    ERROR: "An error has occurred while trying to resend verification code",
  },
  connectedFolderUpdated: {
    SUCCESS: " Connected folder information has been updated",
  },
  deleteClient: {
    SUCCESS: "Client has been deleted",
    ERROR: "An error has occurred while trying to delete client",
  },
  revokeDocument: {
    SUCCESS: "Document has been revoked",
    ERROR: "An error has occurred while trying to revoke document",
  },
  deleteProject: {
    SUCCESS: "Project has been deleted",
    ERROR: "An error has occurred while trying to delete project",
  },
  cancelProject: {
    SUCCESS: "Project has been cancelled",
    ERROR: "An error has occurred while trying to cancel project",
  },
  RevokeClientFromProject: {
    SUCCESS: "Documents have been revoked",
    ERROR: "An error has occurred while trying to revoke documents",
  },
  uploadAndSchedule: {
    SUCCESS: "Documents have been uploaded",
    ERROR:
      "An error has occurred while trying to upload and schedule documents",
  },
  deleteFile: {
    SUCCESS: "File has been removed",
    ERROR: "An error has occurred while trying to remove file",
  },
  updateVisibility: {
    SUCCESS: "Visibility has been updated",
    ERROR: "An error has occurred while trying to update visibility",
  },
  getNodeShares: {
    ERROR: "An error has occurred while trying to get sharing permissions",
  },
} as { [action: string]: { [result: string]: string } }
