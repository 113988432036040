import * as abrdnD2C from "../abrdn/abrdnD2C/abrdnD2C"
import * as abrdnBalmoral from "../abrdn/balmoral/balmoral"
import * as abrdnCapital from "../abrdn/capital/capital"
import * as abrdngibbs from "../abrdn/gibbs/gibbs"
import * as abrdnLudlow from "../abrdn/ludlow/ludlow"
import * as abrdnSheridan from "../abrdn/sheridan/sheridan"
import * as abrdnSuccesion from "../abrdn/succession/succession"
import * as abrdnVanilla from "../abrdn/vanilla/vanilla"
import * as amadeus from "../amadeus/amadeus"
import * as coop from "../co-op/coop"
import * as lifestage from "../lifestage/lifestage"
import * as scholfieldSweeney from "../schofield-sweeney/schofieldSweeney"

import { ThemeNames } from "../../contexts/application/constants"

export function get3TierTheme(theme: string) {
  switch (theme.toUpperCase()) {
    case "CO_OP":
      return coop
    case "AMADEUS":
      return amadeus
    case ThemeNames.ABRDN_D2C:
      return abrdnD2C
    case ThemeNames.ABRDN_VANILLA:
      // ABRDN VANILLA is the default abrdn theme
      return abrdnVanilla
    case ThemeNames.ABRDN_BALMORAL:
      return abrdnBalmoral
    case ThemeNames.ABRDN_CAPITAL:
      return abrdnCapital
    case ThemeNames.ABRDN_LUDLOW:
      return abrdnLudlow
    case ThemeNames.ABRDN_GIBBS:
      return abrdngibbs
    case ThemeNames.ABRDN_SUCCESSION:
      return abrdnSuccesion
    case ThemeNames.ABRDN_SHERIDAN:
      return abrdnSheridan
    case ThemeNames.LIFESTAGE:
      return lifestage
    case ThemeNames.SCHOFIELD_SWEENEY:
      return scholfieldSweeney
    default:
      return coop
  }
}
