import {
  ChangeEvent,
  Dispatch,
  FC,
  KeyboardEvent,
  SetStateAction,
  useState,
} from "react"

import { IFile } from "../../../../../../api/api-client/api-types"
import {
  deleteFile,
  postUploadFileToFolder,
} from "../../../../../../api/lib/node/node"
import { MaxFileNameLength } from "../../../../../../utils/consts/consts"
import {
  InputFieldType,
  validateInput,
} from "../../../../../../utils/forms/validateInputs"
import { BUTTON_VARIANT, Button } from "../../../../../atoms/Button"
import { LoadingSpinner } from "../../../../../atoms/LoadingSpinner/LoadingSpinner"
import AlertMessage, {
  MessageType,
} from "../../../../AlertMessage/AlertMessage"
import { alertMessageErrorsText } from "../../../../AlertMessage/alertMessageText"
import { ModalContent, ModalHeader } from "../../../../Modal"
import { UploadWizardSteps } from "../../../../UploadFileToCaseModalWizard/UploadFileToCaseModalWizard"
import { DisplayDocument } from "../DisplayDocument/DisplayDocument"

import {
  getAllAcceptedFileTypesFromExtensions,
  isAcceptedFileExtension,
  updateDefaultNameForRecentlyCreatedImages,
} from "../../../../ModalJourneyController/utils/utils"

import "./DocumentPageOverview.css"

interface DocumentPageOverviewProps {
  files: IFile[]
  setFiles: Dispatch<SetStateAction<IFile[]>>
  setCurrentStep: Dispatch<SetStateAction<UploadWizardSteps>>
  folderId?: string | null
  failedMultiPageFileNames: string[]
  resetMultiPageFiles?: () => void
}
export const DocumentPageOverview: FC<DocumentPageOverviewProps> = ({
  files,
  setFiles,
  setCurrentStep,
  folderId,
  failedMultiPageFileNames,
  resetMultiPageFiles,
}) => {
  const [isUploadingFile, setIsUploadingFile] = useState(false)
  const [noFilesUploadedError, setNoFilesUploadedError] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [hasInvalidFileName, setHasInvalidFileName] = useState(false)
  const [hasTooLongFileName, setHasTooLongFileName] = useState(false)
  const [fileExtensionsOnError, setFileExtensionsOnError] = useState("")

  const [shouldShowFailedFileNames, setShouldShowFailedFileNames] = useState(
    failedMultiPageFileNames.length > 0
  )

  const removeFile = async (id: string) => {
    try {
      setHasError(false)
      const response = await deleteFile({ fileId: id })

      if (response) {
        const newFiles = files.filter((file) => file.id !== id)
        setFiles(newFiles)
      } else {
        setHasError(true)
      }
    } catch (err) {
      setHasError(true)
    }
  }

  const handleAddNewPage = async (event: ChangeEvent<HTMLInputElement>) => {
    const filesList = event.target.files
    if (filesList) {
      setIsUploadingFile(true)
      setHasInvalidFileName(false)
      setHasTooLongFileName(false)
      setHasError(false)
      setShouldShowFailedFileNames(false)
      setFileExtensionsOnError("")
      resetMultiPageFiles?.()

      // update default file name for images captured, probably, on mobile/ tablet devices
      const updatedFile = updateDefaultNameForRecentlyCreatedImages(
        filesList[0]
      )

      try {
        if (updatedFile.name.length > MaxFileNameLength) {
          setHasTooLongFileName(true)
          setIsUploadingFile(false)

          return
        }

        if (
          !isAcceptedFileExtension(updatedFile, [
            ".pdf",
            ".jpeg",
            ".jpg",
            ".png",
          ])
        ) {
          setFileExtensionsOnError("PDF, JPEG, JPG, PNG")
          setIsUploadingFile(false)
          return
        }

        const fileNameValidationErrors = validateInput({
          type: InputFieldType.LETTERS_NUMBERS_SPACES_DASH_UNDERSCORE_DOT,
          value: updatedFile.name,
        })

        if (fileNameValidationErrors) {
          setHasInvalidFileName(true)
          setIsUploadingFile(false)
          return
        }

        if (folderId) {
          const formData = new FormData()
          formData.append("file", updatedFile, updatedFile.name)
          const response = await postUploadFileToFolder({ folderId, formData })
          setFiles([...files, ...response])
        } else {
          setHasError(true)
        }
      } catch (e) {
        setHasError(true)
      }
    }
    setIsUploadingFile(false)
  }
  const handleKeyPressForAddNewPage = (
    event: KeyboardEvent<HTMLButtonElement>
  ) => {
    if (event.key.toLowerCase() === "enter") {
      document.getElementById("add-page")?.click()
    }
  }

  return (
    <>
      <ModalHeader>
        Do you need to add more pages for this file upload?
      </ModalHeader>
      <ModalContent>
        {noFilesUploadedError && (
          <>
            <AlertMessage
              className="upload-file-to-case-alert-message"
              messageType={MessageType.ERROR}
              title="There's a problem"
              message={alertMessageErrorsText.noFiles}
            />
          </>
        )}
        {hasError && (
          <AlertMessage
            className="document-page-overview-error"
            messageType={MessageType.ERROR}
            title="There's a problem"
            message={alertMessageErrorsText.genericPartialUpload}
          />
        )}
        {hasInvalidFileName && (
          <AlertMessage
            className="document-page-overview-error"
            messageType={MessageType.ERROR}
            title="There's a problem"
            message={alertMessageErrorsText.fileName}
          />
        )}
        {hasTooLongFileName && (
          <AlertMessage
            className="document-page-overview-error"
            messageType={MessageType.ERROR}
            title="There's a problem"
            message={alertMessageErrorsText.fileNameLength}
          />
        )}
        {fileExtensionsOnError.length > 0 && (
          <>
            <AlertMessage
              className="upload-file-to-case-alert-message"
              messageType={MessageType.ERROR}
              title="There's a problem"
              message={`${alertMessageErrorsText.fileType} Please make sure all files are either ${fileExtensionsOnError}.`}
            />
            <hr className="upload-file-to-case-hr" />
          </>
        )}
        {shouldShowFailedFileNames && (
          <AlertMessage
            className="document-page-overview-error"
            messageType={MessageType.ERROR}
            title="There's a problem"
            message={alertMessageErrorsText.genericPartialUpload}
          >
            <ul>
              {failedMultiPageFileNames.map((fileName) => (
                <li key={fileName} title={fileName}>
                  {fileName}
                </li>
              ))}
            </ul>
          </AlertMessage>
        )}
        <div className="document-page-overview-text">
          This document has more than one page that needs photographed or
          scanned?
        </div>
        <input
          className="document-page-overview-file-upload"
          type="file"
          id="add-page"
          onChange={handleAddNewPage}
          accept={getAllAcceptedFileTypesFromExtensions([
            ".pdf",
            ".jpeg",
            ".jpg",
            ".png",
          ])}
        />
        <Button
          variant={BUTTON_VARIANT.SECONDARY}
          onClick={() => setNoFilesUploadedError(false)}
          onKeyDown={handleKeyPressForAddNewPage}
        >
          {!isUploadingFile && (
            <label htmlFor="add-page">Add another page</label>
          )}
          {isUploadingFile && (
            <div className="document-page-overview-processing-file">
              <span className="mr-1">Processing file...</span>
              <LoadingSpinner
                size="20px"
                thickness="2px"
                color="var(--color-universal-secondary-e)"
              />
            </div>
          )}
        </Button>
        <div className="document-page-overview-button-text">
          No more pages are needed, continue to add details and upload.
        </div>
        <Button
          variant={BUTTON_VARIANT.PRIMARY}
          onClick={() => {
            if (files.length > 0) {
              setCurrentStep(UploadWizardSteps.DocumentNameAndCategory)
            } else {
              setNoFilesUploadedError(true)
            }
          }}
        >
          Review upload
        </Button>
        <div className="document-page-overview-display-list-header">
          Images added:
        </div>
        <div className="document-page-overview-display-document-outer">
          {files.map((file) => (
            <DisplayDocument file={file} removeFile={removeFile} />
          ))}
        </div>
      </ModalContent>
    </>
  )
}
