// TODO: remove this once we have the correct types
/* eslint-disable @typescript-eslint/no-explicit-any */
export const getPdfPreviewResponse = (
  dummyPdfFileArrayBuffer: string | ArrayBuffer
) => {
  return {
    data: dummyPdfFileArrayBuffer!,
    status: 200,
    statusText: "",
    headers: {
      "content-length": "239519",
      "content-type": "application/pdf",
    },
    config: {
      transitional: {
        silentJSONParsing: true,
        forcedJSONParsing: true,
        clarifyTimeoutError: false,
      },
      transformRequest: [null],
      transformResponse: [null],
      timeout: 0,
      xsrfCookieName: "XSRF-TOKEN",
      xsrfHeaderName: "X-XSRF-TOKEN",
      maxContentLength: -1,
      maxBodyLength: -1,
      env: {
        FormData: null,
      },
      headers: {
        Accept: "application/json, text/plain, */*",
      },
      method: "get",
      responseType: "blob",
      url: "https://dev-api.legado.io/api/Node/eb5c408e-41a8-4c14-a63b-9ec8eb3e2428/download?logDownload=false",
    },
    request: {},
  }
}
