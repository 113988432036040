export const Comment = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.25895 16.5936C3.14076 14.7086 2.74916 12.4802 3.15768 10.3269C3.56621 8.17362 4.74675 6.24353 6.47764 4.89906C8.20853 3.55459 10.3707 2.88823 12.5581 3.02511C14.7455 3.16199 16.8078 4.0927 18.3575 5.64247C19.9073 7.19223 20.838 9.25445 20.9749 11.4419C21.1118 13.6293 20.4455 15.7915 19.101 17.5224C17.7565 19.2532 15.8264 20.4338 13.6732 20.8423C11.5199 21.2509 9.29149 20.8593 7.40649 19.7411L7.40651 19.741L4.29808 20.6291C4.16947 20.6659 4.03338 20.6676 3.90391 20.634C3.77443 20.6005 3.65628 20.5329 3.5617 20.4383C3.46713 20.3437 3.39956 20.2256 3.36601 20.0961C3.33246 19.9666 3.33415 19.8306 3.37089 19.7019L4.25901 16.5935L4.25895 16.5936Z"
      stroke="var(--button-color-action-text-secondary)"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 10.5H15"
      stroke="var(--button-color-action-text-secondary)"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 13.5H15"
      stroke="var(--button-color-action-text-secondary)"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
