// TODO: remove this once we have the correct types
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from "react"

import {
  getFeatureSetAsModules,
  getIUser,
} from "../../../api/api-client/api-handler"
import {
  setApiConnection,
  setFirstVisit,
  setLoadingState,
  setSelectedTheme,
  setTimeoutState,
} from "../../../contexts/application/action"

import { IUser } from "../../../api/api-client/api-types"
import { ApiConnection } from "../../../api/api-client/common/ApiConnection"
import { ApiController } from "../../../api/apiController"
import { IThumbnailAction } from "../../../contexts/thumbnails/reducer"
import { IToastAction } from "../../../contexts/toasts/reducer"
import { setCurrentUser } from "../../../contexts/users"
import { TThemeName } from "../../../design-tokens/types"
import { initializeGA } from "../../googleAnalytics/initializeGA"

// @ts-ignore-next-line
const isCypress = typeof Cypress !== "undefined"
const isJest = typeof jest !== "undefined"

/**
 * Connects to the API controller and sets up the user in the application state. also sets up the user's webhooks.
 * set the selected theme if it is not already set.
 * sets the first visit flag to false if the user has accepted the terms and conditions or has an activation date.
 * initializes google analytics.
 * sets the timeout state to false. (TODO: investigate if this is necessary)
 * sets the loading state to false and true. (TODO: investigate if this is necessary, specifically the true part)
 * Return the user object if it exists.
 */
export const connectToApiAndReturnUser = async ({
  apiConnection,
  token,
  dispatch,
  dispatchUser,
  thumbnailDispatch,
  toastDispatch,
  email,
  shouldAutoRefreshToken = true,
  themeFromUrl,
}: {
  apiConnection: ApiConnection
  token?: string
  dispatch: (value: any) => void
  dispatchUser: (value: any) => void
  thumbnailDispatch: (value: IThumbnailAction) => void
  toastDispatch: Dispatch<IToastAction>
  email?: string
  shouldAutoRefreshToken?: boolean
  themeFromUrl?: string
}) => {
  try {
    const apiController = ApiController.getInstance()
    apiController.handleTimeout = (isTimedOut = false) => {
      dispatch(setTimeoutState(isTimedOut))
    }

    let user: IUser | undefined

    if (apiController.connected === false) {
      token && apiController.setToken(token)

      const success = await apiController.setApiConnection(apiConnection)
      if (!success) {
        return
      }

      apiController.setAutoRefreshToken(shouldAutoRefreshToken)

      dispatch(setLoadingState(true))

      apiConnection.connected = true
      dispatch(setApiConnection(apiConnection))
      user = await getIUser({ email })

      // Ask Alex? What the below means?
      //If logging in via id_token the username is never set in the api credentials. Preventing a refresh of the authentication
      if (!apiConnection.apiUsername) {
        apiConnection.apiUsername = user?.userName ?? undefined
      }

      const connectionType = token !== undefined ? "SSO" : "developer"
      initializeGA(connectionType, user, user?.platform, themeFromUrl)

      if (user) {
        const featureSet = await getFeatureSetAsModules()
        dispatchUser(setCurrentUser({ ...user, modules: featureSet ?? [] }))

        let theme: string | undefined
        const themeFromFeatureSet = featureSet?.shift()
        theme = themeFromUrl ? themeFromUrl.toUpperCase() : themeFromFeatureSet
        if (theme) {
          dispatch(setSelectedTheme(theme as TThemeName))
        }

        // We don't want to set up webhooks when running Cypress or Jest tests, as it will cause the tests to fail and show errors.
        if (!isCypress && !isJest) {
          apiController.setupUserWebhook(
            dispatch,
            thumbnailDispatch,
            toastDispatch,
            theme ?? ""
          )
        }

        if (user.activationDate || user.acceptedTermsAndConditions) {
          dispatch(setFirstVisit(false))
        }
      }
    }

    return user
  } catch (e) {
    console.error(e)
  }
}
