import { replaceNewLinesWithDotAndSpace } from "../../../utils/string"
import { IFile } from "../../api-client/api-types"
import { baseUrl, httpClient } from "../httpClient"
import { CommentForFile } from "./models/getCommentsForFile"

export const patchUploadFile = async ({
  fileId,
  formData,
}: {
  fileId: string
  formData: FormData
}): Promise<IFile> => {
  const url = new URL(`${baseUrl}/api/Node/upload/${fileId}`)

  const response = await httpClient(url.toString(), {
    method: "PATCH",
    data: formData,
  })

  return response.data
}

export const postUploadFileToFolder = async ({
  folderId,
  formData,
}: {
  folderId: string
  formData: FormData
}): Promise<Array<IFile>> => {
  const url = new URL(`${baseUrl}/api/Node/${folderId}/upload`)

  const response = await httpClient(url.toString(), {
    method: "POST",
    data: formData,
  })

  return response.data
}

export const putRenameUploadedFile = async ({
  fileId,
  name,
}: {
  fileId: string
  name: string
}): Promise<boolean> => {
  const url = new URL(`${baseUrl}/api/Node/file/${fileId}/rename`)

  const response = await httpClient(url.toString(), {
    method: "PUT",
    data: name,
  })

  return response.data
}

export const postUploadAndStitchPages = async ({
  targetFolderId,
  sourceFileIds,
}: {
  targetFolderId: string
  sourceFileIds: string[]
}): Promise<IFile> => {
  const url = new URL(`${baseUrl}/api/Node/pdfstitching`)

  const response = await httpClient(url.toString(), {
    method: "POST",
    data: {
      targetFolderId,
      sourceFileIds,
    },
  })

  return response.data
}

export const postApproveFile = async ({
  fileId,
}: {
  fileId: string
}): Promise<string> => {
  const url = new URL(`${baseUrl}/api/Node/approvefile/${fileId}`)

  const response = await httpClient(url.toString(), {
    method: "POST",
  })

  return response.status === 200 ? "success" : "error"
}

export const deleteFile = async ({
  fileId,
  reasonForDeletion = undefined,
}: {
  fileId: string
  reasonForDeletion?: string
}): Promise<string> => {
  const url = new URL(`${baseUrl}/api/Node/file/${fileId}`)
  if (reasonForDeletion) {
    // This is a workaround to fix the issue with the backend not being able to handle new lines in the comment field
    url.searchParams.set(
      "comment",
      replaceNewLinesWithDotAndSpace(reasonForDeletion)
    )
  }
  const response = await httpClient(url.toString(), {
    method: "DELETE",
  })

  return response.data
}

export const getFileById = async ({
  fileId,
}: {
  fileId: string
}): Promise<IFile> => {
  const url = new URL(`${baseUrl}/api/Node/file/${fileId}/treenode`)

  const response = await httpClient(url.toString(), {
    method: "GET",
  })

  return response.data
}

export const patchUpdateApprovalStatus = async ({
  fileId,
  approvalStatus,
  comment,
}: {
  fileId: string
  approvalStatus: string
  comment?: string
}): Promise<IFile> => {
  const url = new URL(`${baseUrl}/api/Node/approvalStatus/file/${fileId}`)

  const params: { approvalStatus: string; comment?: string } = {
    approvalStatus,
  }
  if (comment) {
    params.comment = comment
  }

  const response = await httpClient(url.toString(), {
    method: "PATCH",
    params,
  })

  return response.data
}

export const getCommentsForFile = async ({
  fileId,
}: {
  fileId: string
}): Promise<CommentForFile[]> => {
  const url = new URL(`${baseUrl}/api/Node/file/${fileId}/comment`)

  const response = await httpClient(url.toString(), {
    method: "GET",
  })

  return response.data
}

export const postCommentsForFile = async ({
  fileId,
  comment,
}: {
  fileId: string
  comment: string
}): Promise<CommentForFile[]> => {
  const url = new URL(`${baseUrl}/api/Node/file/${fileId}/comment`)

  const response = await httpClient(url.toString(), {
    method: "POST",
    data: comment,
  })

  return response.data
}
