import { ReactElement } from "react"
import { Comment } from "./Comment"
import ErrorIcon from "./ErrorIcon"
import MessageInfo from "./MessageInfo"
import NotificationSuccess from "./NotificationSuccess"
import { Tick } from "./Tick"
import { WelcomeLogo } from "./WelcomeLogo"

export const GetIcon = {
  SCHOFIELD_SWEENEYWelcomeLogo: <WelcomeLogo />,
  MessageInfo: <MessageInfo />,
  NotificationSuccess: <NotificationSuccess />,
  Comment: <Comment />,
  Tick: <Tick />,
  ErrorIcon: <ErrorIcon />,
} as { [key: string]: ReactElement }

export default GetIcon
