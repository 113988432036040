// TODO: remove this once we have the correct types
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from "react"
import { IUser } from "../../api/api-client/api-types"
import { IThumbnailAction } from "../../contexts/thumbnails/reducer"
import { IToastAction } from "../../contexts/toasts/reducer"
import { HandleNotificationMessage } from "./HandleWebhookNotification"
import {
  HandleConnectedFolderUpdated,
  HandleFileAdded,
  HandleFileDeleted,
  HandleFileUpdated,
  HandleFolderAdded,
  HandleFolderDeleted,
  HandleFolderUpdated,
  HandleSharedVaultUpdated,
  HandleStatusChange,
  UpdatePreview,
  UpdateReviewInfo,
  UpdateThumbnail,
} from "./UpdateNodesFromWebhookMessage"

export const ParseWebhookMessage = async (
  data: any,
  dispatch: (value: any) => void,
  thumbnailDispatch: (value: IThumbnailAction) => void,
  toastDispatch: Dispatch<IToastAction>,
  theme: string,
  relationship: "currentUser" | "client" | "contact",
  otherUserId?: string,
  currentUser?: IUser
) => {
  if (relationship === "currentUser") {
    if (data["type"]) {
      HandleNonNotificationMessage(
        data,
        dispatch,
        thumbnailDispatch,
        toastDispatch
      )
    } else {
      HandleNotificationMessage(
        data,
        dispatch,
        toastDispatch,
        theme,
        currentUser
      )
    }
  } else {
    otherUserId &&
      HandleSharedVaultUpdated(dispatch, relationship, otherUserId, currentUser)
  }
}

const HandleNonNotificationMessage = async (
  data: any,
  dispatch: (value: any) => void,
  thumbnailDispatch: (value: IThumbnailAction) => void,
  toastDispatch: Dispatch<IToastAction>
) => {
  switch (data["type"]) {
    case "FileUpload":
      HandleFileUploadMessage(data, dispatch, thumbnailDispatch)
      break
    case "FileUpdate":
      HandleFileUpdateMessage(data, dispatch)
      break
    case "FolderUpdate":
      HandleFolderUpdateMessage(data, dispatch)
      break
    case "ConnectedStatusChange":
      HandleConnectedFolderUpdateMessage(data, dispatch, toastDispatch)
      break
    default:
      console.log("Unknown NonNotification type received: " + data["type"])
  }
}

enum FileUploadStatus {
  FILE_PROCESS_STARTED,
  THUMBNAIL_COMPLETE,
  PREVIEW_COMPLETE,
  REVIEW_STARTED,
  REVIEW_COMPLETE,
  REVIEW_SKIPPED,
}

const HandleFileUploadMessage = async (
  data: any,
  dispatch: (value: any) => void,
  thumbnailDispatch: (value: IThumbnailAction) => void
) => {
  switch (data["status"] as number) {
    case FileUploadStatus.FILE_PROCESS_STARTED:
      break
    case FileUploadStatus.THUMBNAIL_COMPLETE:
      UpdateThumbnail(data, dispatch, thumbnailDispatch)
      break
    case FileUploadStatus.PREVIEW_COMPLETE:
      UpdatePreview(data, dispatch)
      break
    case FileUploadStatus.REVIEW_STARTED:
      break
    case FileUploadStatus.REVIEW_COMPLETE:
      UpdateReviewInfo(data, dispatch)
      break
    case FileUploadStatus.REVIEW_SKIPPED:
      break
    default: {
      console.log("Unknown FileUpload status received: " + data["status"])
      break
    }
  }
}

enum UpdateType {
  ADD,
  UPDATE,
  DELETE,
  STATUS_CHANGE,
  LOGIN_FAILED,
  TWO_FACTOR_REQUIRED,
}

const HandleFileUpdateMessage = async (
  data: any,
  dispatch: (value: any) => void
) => {
  switch (data["status"] as number) {
    case UpdateType.ADD:
      HandleFileAdded(data, dispatch)
      break
    case UpdateType.UPDATE:
      HandleFileUpdated(data, dispatch)
      break
    case UpdateType.DELETE:
      HandleFileDeleted(data, dispatch)
      break
    default:
      console.log("Unknown FileUpdate status received: " + data["status"])
      break
  }
}

const HandleFolderUpdateMessage = async (
  data: any,
  dispatch: (value: any) => void
) => {
  switch (data["status"] as number) {
    case UpdateType.ADD:
      HandleFolderAdded(data, dispatch)
      break
    case UpdateType.UPDATE:
      HandleFolderUpdated(data, dispatch)
      break
    case UpdateType.DELETE:
      HandleFolderDeleted(data, dispatch)
      break
    default:
      console.log("Unknown FolderUpdate status received: " + data["status"])
      break
  }
}

const HandleConnectedFolderUpdateMessage = async (
  data: any,
  dispatch: (value: any) => void,
  toastDispatch: Dispatch<IToastAction>
) => {
  switch (data["status"] as number) {
    case UpdateType.UPDATE:
      HandleConnectedFolderUpdated(data, dispatch, toastDispatch)
      break
    default:
      HandleStatusChange(data, dispatch, toastDispatch)
      break
  }
}
