// This should not be disabled!
/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames"
import { FC, useEffect, useMemo, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { IFile, IFolder } from "../../../api/api-client/api-types"
import {
  convertApiToAppUsers,
  convertFilesDtoToIFiles,
} from "../../../api/api-client/convert"
import { ApiController } from "../../../api/apiController"
import {
  setClients,
  setFilesWithTags,
  setSearchedTag,
  setSearchResult,
  setSelectedContact,
  setSelectedFile,
  setSelectedFolder,
  setSelectedUserSharingWithMe,
} from "../../../contexts/application/action"
import {
  UserModuleType,
  UserRoles,
} from "../../../contexts/application/constants"
import { useApplicationContext } from "../../../contexts/application/context"
import { useUserContext } from "../../../contexts/users"
import { useGetIcon } from "../../../styled-components/GetIconLibraryInTheme"
import { pageString } from "../../../utils/helpers"
import { searchClients } from "../../../utils/search/searchClients"
import {
  searchFoldersAndFiles,
  searchFoldersAndFilesByIds,
} from "../../../utils/search/searchFoldersAndFiles"
import { findTagsForFiles, searchTags } from "../../../utils/search/searchTags"
import "./SearchBarWithApplicationContext.css"

export interface IPrevState {
  selectedFileId?: string
  selectedFolderId?: string
  selectedContactId?: string
  selectedSharingWithMeUserId?: string
  sharedWithMeFolders?: IFolder[]
}

export const SearchBarWithApplicationContext: FC<{ className?: string }> = ({
  className,
}) => {
  const {
    applicationState: {
      folders,
      selectedFileId,
      selectedFolderId,
      selectedContactId,
      selectedSharingWithMeUserId,
      sharedWithMeFolders,
      usersSharingWithMe,
      selectedTheme,
      filesWithTags,
      searchedTag,
      clients,
      flatFileList,
    },
    dispatch,
  } = useApplicationContext()
  const {
    userState: { currentUser },
  } = useUserContext()

  const api = ApiController.getInstance()

  const { pathname } = useLocation()
  const navigate = useNavigate()

  const [searchExpression, setSearchExpression] = useState("")
  const prevState = useRef<IPrevState>()

  const hasFlatFileListModule = currentUser?.modules?.includes(
    UserModuleType.FLAT_FILE_LIST
  )

  useEffect(() => {
    getClients()
    getTags()
  }, [])

  const getClients = async () => {
    if (currentUser?.modules?.includes(UserModuleType.CLIENT_LIST)) {
      const clientList = await api.getSharingClientsList()
      const clientsAsIUsers = convertApiToAppUsers(clientList)
      dispatch(setClients(clientsAsIUsers))
    }
  }

  const getTags = async () => {
    if (currentUser?.modules?.includes(UserModuleType.TAGGING)) {
      if (!filesWithTags || filesWithTags.length > 0) return
      const newTags = await api.getFileTags()
      dispatch(setFilesWithTags(newTags))
    }
  }

  const getSearchableFolders = () => {
    if (hasFlatFileListModule) return []
    if (prevState.current?.selectedSharingWithMeUserId)
      return prevState.current?.sharedWithMeFolders ?? []
    return folders
  }

  useEffect(() => {
    if (pathname !== "/search-result") {
      setSearchExpression("")
      prevState.current = undefined
    }
  }, [pathname])

  useEffect(() => {
    handleSearch(searchExpression)
  }, [filesWithTags])

  useEffect(() => {
    if (searchedTag && searchedTag !== "") {
      const filesWithTag = findTagsForFiles(filesWithTags)
      const fileIds = filesWithTag.find((tag) => tag.tagName === searchedTag)
      const searchableFolders = getSearchableFolders()
      let newSearchResult = searchFoldersAndFilesByIds({
        ids: fileIds?.files ?? [],
        folders: searchableFolders,
      })
      dispatch(setSearchResult(newSearchResult))
    } else {
      handleSearch(searchExpression)
    }
  }, [searchedTag])

  const handleSearch = (searchTerm: string) => {
    dispatch(setSearchedTag(""))
    setSearchExpression(searchTerm)
    if (searchTerm !== "") {
      if (pathname !== "/search-result") {
        prevState.current = {
          selectedFileId,
          selectedFolderId,
          selectedContactId,
          selectedSharingWithMeUserId,
          sharedWithMeFolders,
        }
        navigate("/search-result")
      }
      const searchableFolders = getSearchableFolders()

      let newSearchResult = searchFoldersAndFiles({
        expression: searchTerm,
        folders: searchableFolders,
      })
      newSearchResult.tags = searchTags({
        expression: searchTerm,
        filesAndTags: filesWithTags,
      })

      let wasOnUser = !!prevState.current?.selectedSharingWithMeUserId

      if (
        !selectedSharingWithMeUserId ||
        (isAdviser && hasFlatFileListModule)
      ) {
        newSearchResult.clients = searchClients({
          expression: searchTerm,
          clients: clients,
          // if we are on the flat file list module, we need to search clients
          // because the top bar will only ever be used to search clients
          wasOnUser: hasFlatFileListModule ? false : wasOnUser,
        })
      }

      if (!isAdviser && hasFlatFileListModule && !!flatFileList) {
        newSearchResult.files = convertFilesDtoToIFiles(flatFileList).filter(
          (file) => file.name?.toLowerCase().includes(searchTerm.toLowerCase())
        )

        // filter by unique file ids so we don't get duplicates if someone turns incompatible features on
        newSearchResult.files = newSearchResult.files.reduce<IFile[]>(
          (acc, file) => {
            if (!acc.some((f) => f.id === file.id)) {
              acc.push(file)
            }
            return acc
          },
          []
        )
      }

      dispatch(setSearchResult(newSearchResult))
    } else if (pathname === "/search-result") {
      if (prevState.current?.selectedFileId)
        dispatch(setSelectedFile(prevState.current.selectedFileId))
      if (prevState.current?.selectedFolderId)
        dispatch(setSelectedFolder(prevState.current.selectedFolderId))
      if (prevState.current?.selectedContactId)
        dispatch(setSelectedContact(prevState.current.selectedContactId))
      if (prevState.current?.selectedSharingWithMeUserId)
        dispatch(
          setSelectedUserSharingWithMe(
            prevState.current.selectedSharingWithMeUserId
          )
        )
      navigate(-1)
    }
  }

  const crossIcon = useGetIcon("Cross")
  const searchIcon = useGetIcon("Search")
  let isAdviser = currentUser?.roles?.includes(UserRoles.Adviser)
  const hasOrganisationAdminModule = currentUser?.modules?.includes(
    UserModuleType.ORGANISATIONS_ADMIN
  )

  const label = useMemo(() => {
    if (isAdviser && hasFlatFileListModule) return "Search client list"
    if (!selectedSharingWithMeUserId)
      return isAdviser ? "Search client list" : "Search document vault"
    const user = usersSharingWithMe.find(
      (user) =>
        user.userId === selectedSharingWithMeUserId ||
        user.userId === prevState.current?.selectedSharingWithMeUserId
    )
    if (!user) return "Search document vault"

    if (hasOrganisationAdminModule) {
      return "Search Organisations"
    }

    const name = user?.firstName ?? user?.surname ?? "user"
    const nameEndsOnS = name.endsWith("s")
    var fullName = `${name}'${!nameEndsOnS ? "s" : ""}`
    return `Search ${fullName} document vault`
  }, [selectedSharingWithMeUserId, usersSharingWithMe])

  const isClientView = selectedTheme === "ONE_X" && pathname === "/clients"
  const hideDoubleSearchBar = currentUser?.modules?.includes(
    UserModuleType.DOCUMENT_HUB
  )

  const searchBarClass = classNames("search-bar", {
    "client-view": isClientView,
    "hide-double-search-bar": hideDoubleSearchBar,
    className,
  })

  const searchInputClass = classNames("search-input", {
    "has-content": searchExpression !== "",
  })

  const searchIconContainerClass = classNames("search-icon", {
    "darker-icon": searchExpression !== "",
  })

  return (
    <div
      className={searchBarClass}
      data-testid={pageString() + "-search-bar-div"}
    >
      <form role="search" onSubmit={(e) => e.preventDefault()}>
        <label className="visually-hidden" htmlFor="search-bar-input-id">
          search-expression
        </label>
        <input
          value={searchExpression}
          className={searchInputClass}
          onChange={(e) => {
            handleSearch(e.target.value)
          }}
          placeholder={`${label}...`}
          type="search"
          aria-label={label}
          id="search-bar-input-id"
          role="searchbox"
        />
        {searchExpression && (
          <button
            className="crossIcon"
            onClick={() => {
              handleSearch("")
            }}
            title="Clear search"
            type="button"
          >
            {crossIcon}
          </button>
        )}
        <span className={searchIconContainerClass}>{searchIcon}</span>
      </form>
    </div>
  )
}
