// TODO: remove this once we have the correct types
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getOrganisationController } from "../../../api/ApiControllers/helpers"
import { useApplicationContext } from "../../../contexts/application/context"
import AberdnLogoSvg from "../../../design-tokens/imgs/abrdn/AberdnLogoSvg"
import "./TopBarAbrdn.css"

type TopBarAbrdnProps = {
  title: string
  subtitle?: string
  shouldGetLogoFromApi?: boolean
}

export const TopBarAbrdn: React.FC<TopBarAbrdnProps> = ({
  title,
  subtitle,
  shouldGetLogoFromApi = false,
}) => {
  const {
    applicationState: { headAccountId },
  } = useApplicationContext()
  const navigate = useNavigate()

  const [logoUri, setLogoUri] = useState<string>("")

  useEffect(() => {
    if (shouldGetLogoFromApi) {
      const defaultLogo =
        "https://prd-cdn.abrdn.com/-/jssmedia/abrdn/logo/abrdn-logo-horiz-black-svg.ashx?iar=0"
      const fetchData = async () => {
        try {
          const api = getOrganisationController()
          const logoData = await api.getLogoAndAdviserFirm()
          setLogoUri(logoData.uri ?? defaultLogo)
        } catch (error: any) {
          setLogoUri(defaultLogo)
        }
      }
      fetchData()
    }
  }, [shouldGetLogoFromApi])

  return (
    <div className="topBar-abrdn-border">
      <div className="topBar-abrdn container">
        {headAccountId && logoUri && (
          <img className="topBar-abrdn_logo" src={logoUri} alt="Logo" />
        )}
        {headAccountId && !logoUri && <AberdnLogoSvg />}

        {!headAccountId && (
          <a
            href={"/"}
            onClick={(e) => {
              e.preventDefault()
              navigate("/")
            }}
          >
            {logoUri ? (
              <img className="topBar-abrdn_logo" src={logoUri} alt="Logo" />
            ) : (
              <AberdnLogoSvg />
            )}
          </a>
        )}
        <div className="topBar-abrdn-headings">
          <h1 className="topBar-abrdn-title">{title}</h1>
          {subtitle && <p className="topBar-abrdn-subtitle">{subtitle}</p>}
        </div>
      </div>
    </div>
  )
}
