// TODO: remove this once we have the correct types
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChildOrganisationDto,
  LogoDto,
  OrganisationApi,
  OrganisationDto,
} from "legado-generated-api-client"
import { ApiController } from "../apiController"

export class OrganisationController extends ApiController {
  private apiController: ApiController
  constructor(apiController: ApiController) {
    super()
    this.apiController = apiController
  }

  private getOptions(withToken: boolean, formData: FormData = new FormData()) {
    type OptionValues = { [key: string]: any }
    type HeaderValues = { [key: string]: string }

    const headerValues: HeaderValues = {}
    if (this.apiController.getApiConnection.apiKey) {
      headerValues["ApiKey"] = this.apiController.getApiConnection.apiKey
    }

    if (withToken) {
      headerValues["Authorization"] = "Bearer " + this.apiController.getApiToken
    }

    var optionValues: OptionValues = {}
    optionValues["headers"] = headerValues
    optionValues["data"] = formData
    optionValues["Content-Type"] = "multipart/form-data"

    return optionValues
  }
  public async postOrganisation(
    organisation: OrganisationDto,
    secondAttempt: boolean = false
  ): Promise<OrganisationDto> {
    if (this.apiController.connected) {
      try {
        const organisationApi = new OrganisationApi({
          basePath: this.apiController.getBaseUrl,
        })
        const response = await organisationApi.apiOrganisationCreatePost(
          organisation,
          this.getOptions(true)
        )
        return response.data
      } catch (error) {
        await this.HandleError(
          error,
          "Could not post organisation",
          secondAttempt
        )
        return {}
      }
    }
    return {}
  }

  public async getOrganisation(
    id: string,
    secondAttempt: boolean = false
  ): Promise<OrganisationDto> {
    if (this.apiController.connected) {
      try {
        const organisationApi = new OrganisationApi({
          basePath: this.apiController.getBaseUrl,
        })
        const response = await organisationApi.apiOrganisationOrganisationIdGet(
          id,
          this.getOptions(true)
        )
        return response.data
      } catch (error) {
        var shouldRetry = await this.HandleError(
          error,
          `Could not get organisation with id: ${id}`,
          secondAttempt
        )
        if (shouldRetry) return await this.getOrganisation(id, true)
        return {}
      }
    } else {
      throw new Error("Do not use static data, use MSW instead")
    }
  }

  public async getAllOrganisations(
    partialOrganisationName?: string,
    secondAttempt: boolean = false
  ): Promise<OrganisationDto[]> {
    if (this.apiController.connected) {
      try {
        const organisationApi = new OrganisationApi({
          basePath: this.apiController.getBaseUrl,
        })
        const response = await organisationApi.apiOrganisationPlatformGet(
          partialOrganisationName,
          this.getOptions(true)
        )
        return response.data
      } catch (error) {
        var shouldRetry = await this.HandleError(
          error,
          "Could not get organisations",
          secondAttempt
        )
        if (shouldRetry)
          return await this.getAllOrganisations(partialOrganisationName, true)
        return []
      }
    } else {
      throw new Error("Do not use static data, use MSW instead")
    }
  }

  public async getAllSingleAdviserCompanies(
    parentOrganisationId: string,
    partialOrganisationName?: string,
    secondAttempt: boolean = false
  ): Promise<OrganisationDto[]> {
    if (this.apiController.connected) {
      try {
        const organisationApi = new OrganisationApi({
          basePath: this.apiController.getBaseUrl,
        })
        const response =
          await organisationApi.apiOrganisationOrganisationIdSingleadviserGet(
            parentOrganisationId,
            partialOrganisationName,
            this.getOptions(true)
          )
        return response.data
      } catch (error) {
        await this.HandleError(
          error,
          "Could not get advisers for organisation",
          secondAttempt
        )
        return []
      }
    } else {
      throw new Error("Do not use static data, use MSW instead")
    }
  }

  public async getAllChildOrganisations(
    parentOrganisationId: string,
    secondAttempt: boolean = false
  ): Promise<ChildOrganisationDto[]> {
    if (this.apiController.connected) {
      try {
        const organisationApi = new OrganisationApi({
          basePath: this.apiController.getBaseUrl,
        })
        const response =
          await organisationApi.apiOrganisationOrganisationIdChildorgsGet(
            parentOrganisationId,
            this.getOptions(true)
          )
        //TODO: remove this once back end removes the 204's https://dev.azure.com/secure-the-file/Application/_workitems/edit/15317
        if (response.status === 204) {
          return []
        }
        return response.data
      } catch (error) {
        await this.HandleError(
          error,
          "Could not get child organisations for organisation",
          secondAttempt
        )
        return []
      }
    } else {
      throw new Error("Do not use static data, use MSW instead")
    }
  }

  public async getLogoAndAdviserFirm(
    secondAttempt: boolean = false
  ): Promise<LogoDto> {
    if (this.apiController.connected) {
      try {
        const organisationApi = new OrganisationApi({
          basePath: this.apiController.getBaseUrl,
        })
        const response = await organisationApi.apiOrganisationLogoGet(
          this.getOptions(true)
        )
        return response.data
      } catch (error) {
        await this.HandleError(
          error,
          "Could not get logo for organisation",
          secondAttempt
        )
        return {}
      }
    } else {
      throw new Error("Do not use static data, use MSW instead")
    }
  }
}
