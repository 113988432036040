// TODO: remove this once we have the correct types
/* eslint-disable @typescript-eslint/no-explicit-any */
import ReactGA from "react-ga4"

import { IUser } from "../../api/api-client/api-types"
import { userRolesToGARoles } from "../../data/google-analytics/helpers"

export const initializeGA = (
  loginType: "SSO" | "developer" | "static",
  currentUser?: IUser,
  platform?: any,
  themeFromUrl?: string
) => {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID!, {
    testMode:
      !process.env.NODE_ENV ||
      process.env.NODE_ENV === "development" ||
      process.env.NODE_ENV === "test",
    gaOptions: {
      cookieDomain: "none",
      Organisation_ID: currentUser?.organisationId ?? "No Organisation",
      user_Role:
        userRolesToGARoles(currentUser?.roles?.join()) ?? "No User Role",
      login_type_tmp: loginType,
      corporate_platform: themeFromUrl
        ? themeFromUrl.toLowerCase()
        : platform || "No Platform",
    },
  })
}
