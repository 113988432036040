import { FC, useEffect, useRef, useState } from "react"
import {
  SUCCESS_NOTIICATION_DISAPPEARS_AFTER_MS,
  ThemeNames,
  UserModuleType,
  UserRoles,
} from "../../contexts/application/constants"
import {
  setDisplaySuccessOrErrorMessage,
  useToastContext,
} from "../../contexts/toasts"
import { castToBool, pageString } from "../../utils/helpers"
import {
  FoldersSharingNavigationItems,
  TLeftBarNavigationData,
} from "../LeftNavigationBar/FoldersSharingNavigationItems/FoldersSharingNavigationItems"
import { Toast, ToastType } from "../modules/Toast/Toast"
import { GetFooter, GetTopbar, ShowSearchBar } from "./helper"

import classNames from "classnames"
import { createPortal } from "react-dom"
import { useLocation } from "react-router-dom"
import { useApplicationContext } from "../../contexts/application/context"
import { useUserContext } from "../../contexts/users"
import { MoneyhubSidebarDesktop } from "../../design-tokens/imgs/moneyhub/moneyhubSidebarDesktop"
import { useLeftNavMenu } from "../../hooks/useLeftNavMenu"
import { isNativeMobile } from "../../utils/consts/platform"
import { UpdateExpandedLinksWhenNavigating } from "../LeftNavigationBar/FoldersSharingNavigationItems/helpers"
import { InitialNavigationData } from "../LeftNavigationBar/FoldersSharingNavigationItems/utils"
import { LeftNavigationBar } from "../LeftNavigationBar/LeftNavigationBar"
import { LogoutLink } from "../LogoutLink/LogoutLink"
import { MenuBar } from "../MenuBar"
import { LeftNavMobileMenu } from "../MenuBar/LeftNavMobileMenu"
import { NotificationButton } from "../NotificationIcon/NotificationButton"
import { ProfileButton } from "../atoms/ProfileButton/ProfileButton"
import { SessionTimeoutModal } from "../atoms/SessionTimedOut/SessionTimedOut"
import { NotificationPopUp } from "../modules/NotificationPopUp"
import { LoadingFallBack } from "../organism/LoadingFallBack"

import { SearchBarWithApplicationContext } from "../Search/SearchBarWithApplicationContext/SearchBarWithApplicationContext"
import SearchBarWithContext from "../Search/SearchBarWithContext/SearchBarWithContext"
import "./Main.scss"

const MoneyhubLeftNavigationBar: React.FC<{
  text?: string
}> = () => (
  <div className="left-nav-content moneyhub-sidebar hide-on-tablet-and-down">
    <img
      alt="sidebar"
      src={MoneyhubSidebarDesktop.img}
      className="hide-on-tablet-and-down"
    />
  </div>
)

const ToastDependantOnTheme = () => {
  const {
    applicationState: { selectedTheme },
  } = useApplicationContext()
  const {
    toastState: { displaySuccessOrErrorPopup },
    dispatch: toastDispatch,
  } = useToastContext()

  useEffect(() => {
    if (displaySuccessOrErrorPopup) {
      setTimeout(() => {
        toastDispatch(setDisplaySuccessOrErrorMessage(undefined))
      }, SUCCESS_NOTIICATION_DISAPPEARS_AFTER_MS)
    }
  }, [displaySuccessOrErrorPopup, toastDispatch])

  if (!displaySuccessOrErrorPopup) {
    return <></>
  }

  switch (selectedTheme) {
    case "CO_OP":
      return (
        <Toast
          title={displaySuccessOrErrorPopup?.title ?? ""}
          toastType={
            displaySuccessOrErrorPopup?.messageType === "SUCCESS"
              ? ToastType.SUCCESS
              : ToastType.INFO
          }
          message={displaySuccessOrErrorPopup?.message ?? ""}
        />
      )
    default:
      return <NotificationPopUp />
  }
}

export const MainLayout: FC<{
  children?: React.ReactNode
  basePath?: string
}> = ({ children, basePath }) => {
  const {
    applicationState: {
      isLoading,
      selectedTheme,
      modalOpen,
      notifications,
      showMenu,
      timeoutState,
      selectedFolderId,
      folders,
      showFooter,
    },
  } = useApplicationContext()
  const {
    userState: { currentUser },
  } = useUserContext()

  const [hideBrandedTopBar, setHideBrandedTopBar] = useState(false)
  const [expandedLinks, setExpandedLinks] = useState<TLeftBarNavigationData>(
    InitialNavigationData
  )
  const brandedBar = useRef<HTMLDivElement>(null)
  const { isLeftNavShowing, setIsLeftNavShowing, isDesktop } = useLeftNavMenu()
  const { pathname } = useLocation()

  useEffect(() => {
    if (!isNativeMobile || !brandedBar.current) return
    const observerHandler = (entries: IntersectionObserverEntry[]) => {
      if (!entries[0].isIntersecting) {
        setHideBrandedTopBar(true)
        observer.disconnect()
      }
    }
    const observer = new IntersectionObserver(observerHandler)
    observer.observe(brandedBar.current!)
    return () => {
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    const handleDrag = (event: DragEvent) => {
      event.preventDefault()
      if (event.dataTransfer) event.dataTransfer.dropEffect = "none"
    }
    window.addEventListener("dragover", handleDrag)
    window.addEventListener("dragenter", handleDrag)
    return () => {
      window.removeEventListener("dragover", handleDrag)
      window.removeEventListener("dragenter", handleDrag)
    }
  }, [])

  useEffect(() => {
    const getLinksBasedOnNavigation = () => {
      const newExpandedLinks = UpdateExpandedLinksWhenNavigating({
        folderId: selectedFolderId,
        folders,
        pathname,
      })

      if (
        newExpandedLinks.level1 !== expandedLinks.level1 ||
        newExpandedLinks.level2 !== expandedLinks.level2 ||
        newExpandedLinks.level3 !== expandedLinks.level3
      ) {
        setExpandedLinks(newExpandedLinks)
      }
    }
    getLinksBasedOnNavigation()
    // TODO: we should not disable the eslint rule, we should fix the dependency array https://dev.azure.com/secure-the-file/Application/_workitems/edit/15329
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFolderId, pathname, folders])

  const envHasTopBar = castToBool(process.env.REACT_APP_WITH_BRANDED_BAR)
  const themeHasBrandedBar =
    selectedTheme === "SCOTTISH_WIDOWS" ||
    selectedTheme === "ONE_X" ||
    selectedTheme === "BARCLAYS" ||
    selectedTheme === "JAMES_HAY" ||
    selectedTheme === "QUILTER" ||
    selectedTheme === "THE_FAMILY_ELEPHANT" ||
    selectedTheme === "MONEYHUB" ||
    selectedTheme === ThemeNames.ABRDN_D2C ||
    selectedTheme === ThemeNames.ABRDN_VANILLA ||
    selectedTheme === ThemeNames.ABRDN_BALMORAL ||
    selectedTheme === ThemeNames.ABRDN_CAPITAL ||
    selectedTheme === ThemeNames.ABRDN_LUDLOW ||
    selectedTheme === ThemeNames.ABRDN_GIBBS ||
    selectedTheme === ThemeNames.ABRDN_SUCCESSION ||
    selectedTheme === ThemeNames.ABRDN_SHERIDAN ||
    selectedTheme === ThemeNames.LIFESTAGE

  const userHasLeftNavBarModule = currentUser?.modules?.includes(
    UserModuleType.LEFT_NAV
  )

  const userHasOrganisationsAdminModule = currentUser?.modules?.includes(
    UserModuleType.ORGANISATIONS_ADMIN
  )

  const newNotificationCount =
    notifications?.filter((n) => n.viewed !== undefined && !n.viewed).length ??
    0

  const theFooter = GetFooter()
  const theTopbar = GetTopbar()

  const LeftNavigationBarWithFoldersAndSharing = () => {
    document.body.style.overflow =
      isLeftNavShowing && !isDesktop ? "hidden" : "unset"

    const portalTarget =
      document.body.querySelector("#menu-bar") ?? document.body

    //See "Legado menu bar" for comments detailing usage
    return (
      <>
        {!isDesktop &&
          createPortal(
            <LeftNavMobileMenu
              isExpanded={isLeftNavShowing}
              onMenuClick={() => setIsLeftNavShowing(true)}
            />,
            portalTarget
          )}
        {(isDesktop || (!isDesktop && isLeftNavShowing)) && (
          <LeftNavigationBar
            isDesktop={isDesktop}
            setIsMenuBarExpanded={setIsLeftNavShowing}
            isMenuBarExpanded={isLeftNavShowing}
          >
            <FoldersSharingNavigationItems
              isDesktop={isDesktop}
              setIsMenuBarExpanded={setIsLeftNavShowing}
              isMenuBarExpanded={isLeftNavShowing}
              setExpandedLinks={setExpandedLinks}
              expandedLinks={expandedLinks}
            />
          </LeftNavigationBar>
        )}
      </>
    )
  }

  const pageWrapperClass = classNames("page-wrapper", {
    "prevent-click": modalOpen,
  })
  const pageContentClass = classNames("page-content", {
    onex: selectedTheme === "ONE_X",
  })

  const isAbrdnPrimaryUser =
    selectedTheme?.includes("ABRDN") &&
    currentUser?.roles?.includes(UserRoles.PrimaryUser)

  const mainLayoutClasses = classNames("position-relative", "main-layout", {
    "abrdn-primary-user": isAbrdnPrimaryUser,
  })

  return (
    <div className={pageWrapperClass}>
      {userHasLeftNavBarModule &&
        ((selectedTheme === "MONEYHUB" && <MoneyhubLeftNavigationBar />) ||
          (selectedTheme !== "CO_OP" && (
            <LeftNavigationBarWithFoldersAndSharing />
          )))}

      <div className={`${mainLayoutClasses} ${pageString()}`}>
        {envHasTopBar && !hideBrandedTopBar && themeHasBrandedBar && (
          <div ref={brandedBar}>{theTopbar}</div>
        )}

        {userHasLeftNavBarModule &&
        selectedTheme !== "MONEYHUB" &&
        selectedTheme !== "CO_OP" ? (
          <>
            <div id="menu-bar" />
            <div className="container search-bar-wrapper">
              {ShowSearchBar(currentUser) &&
                (userHasOrganisationsAdminModule ? (
                  <SearchBarWithContext />
                ) : (
                  <SearchBarWithApplicationContext />
                ))}
              {!userHasOrganisationsAdminModule && (
                <NotificationButton newNotifications={newNotificationCount} />
              )}
              {ShowSearchBar(currentUser) && <LogoutLink />}
              <ProfileButton className="hide-on-tablet-and-down" />
            </div>
          </>
        ) : (
          showMenu && <MenuBar basePath={basePath} />
        )}
        <ToastDependantOnTheme />
        <div className={pageContentClass}>{children}</div>
        {timeoutState && <SessionTimeoutModal />}
        {isLoading && <LoadingFallBack />}
        {envHasTopBar && showFooter && <div>{theFooter}</div>}
      </div>
    </div>
  )
}
